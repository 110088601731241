import { HourAndMinuteRows } from "./HourAndMinuteRows"
import { SchedulesCalendarHeader } from "./SchedulesCalendarHeader"

export default function SchedulesCalendar({ headerContent, configuration, size = { height: '85vh', width: 'auto' }, children, selectedDate, onDateSelected }) {
  const isToday = selectedDate.getDate() === new Date().getDate()
  return <>
    <SchedulesCalendarHeader
      headerContent={headerContent}
      selectedDate={selectedDate}
      onDateSelected={onDateSelected}
    />
    <div style={{
      margin: '1rem',
      position: 'relative',
      height: size.height,
      width: size.width,
      overflowY: 'scroll',
      overflowX: 'auto'
    }}>
      <HourAndMinuteRows
        minuteHeight={configuration.minuteHeight}
        openingTime={configuration.openingTime}
        closingTime={configuration.closingTime}
        showCurrentTimeLine={isToday}
      />
      {children}
    </div>
  </>
}
