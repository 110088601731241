import { Add } from '@mui/icons-material'
import { Fab } from '@mui/material'
import React, { Children } from 'react'
import List from '../List'

export default function NonQueriableList({ emptyListView, onAdd, component = 'nav', subheaders, children }) {
    const hasItems = Children.count(children) > 0
    const customEmptyView = !hasItems ? emptyListView : null

    return (
        customEmptyView ??
        <>
            <List
                component={component}
                subheaders={subheaders}
            >
                {children}
            </List>
            <AddButton onClick={onAdd} />
        </>
    )
}

const AddButton = (props) => {
    return !props.onClick ? null : <Fab
        style={{ position: 'fixed', right: 20, bottom: 20 }}
        onClick={e => {
            e.preventDefault()
            props.onClick()
        }} variant='extended' color='secondary'>
        <Add />
        Agregar
    </Fab>
}