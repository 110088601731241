import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class RemoteClientsRepository {
    constructor(business) {
        this.business = business
    }

    async getClientById(id) {
        const response = await jsonRequest(`${baseURL}/businesses/${this.business}/clients/${id}`)
        return response.data.client
    }

    async getAllClients(query) {
        const url = `${baseURL}/businesses/${this.business}/clients?q=${query}`
        return await jsonRequest(`${url}`)
            .then(response => response.data.clients)
    }

    async editClient(client) {
        const url = `${baseURL}/businesses/${this.business}/clients`
        return await jsonRequest(`${url}`, { method: 'PUT', body: client })
            .then(response => response.data.clients)
    }

    async createClient(client) {
        const url = `${baseURL}/businesses/${this.business}/clients`
        return await jsonRequest(`${url}`, { method: 'POST', body: client })
            .then(response => response.data.clients)
    }
}