import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { calculateLeftPosition } from "../presentation/CalendarItemUtils"
import DetailedScheduleCalendarItem from "../presentation/DetailedScheduleCalendarItem"
import { ScheduleCalendarItem } from "../presentation/ScheduleCalendarItem"
import SchedulesCalendar from "../presentation/SchedulesCalendar"

const SchedulesCalendarHomeFlow = ({ repository, configurationProvider, showModifyScheduleFlow, showNewScheduleFlow }) => {
    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [schedules, setSchedules] = useState([])

    useEffect(() => {
        // refresh every 5 minutes
        repository.getSchedulesForDate(selectedDate)
            .then(setSchedules)
        const interval = setInterval(() => {
            repository.getSchedulesForDate(selectedDate)
                .then(setSchedules)
        }, 300000)
        return () => clearInterval(interval)
    }, [selectedDate, repository])

    const configuration = {
        ...configurationProvider.getConfiguration(),
        minuteHeight: 3,
    }

    async function onArrivedClick(s) {
        const newSchedule = await repository.markClientArrived(s.id)
        setSchedules(schedules.map(s => s.id === newSchedule.id ? newSchedule : s))
    }

    function itemWidth() {
        // for small screens
        if (window.innerWidth < 600) {
            return 100
        }
        // for medium screens
        if (window.innerWidth < 1000) {
            return 200
        }
        // for large screens
        return 300
    }

    function detailedItemWidth() {
        // for small screens
        if (window.innerWidth < 600) {
            return '80%'
        }
        // for medium screens
        if (window.innerWidth < 1000) {
            return 200
        }
        // for large screens
        return 300
    }

    function leftPositionBias() {
        // for small screens
        if (window.innerWidth < 600) {
            return 110
        }
        // for medium screens
        if (window.innerWidth < 1000) {
            return 220
        }
        // for large screens
        return 330
    }

    return <>
        <SchedulesCalendar
            configuration={configuration}
            selectedDate={selectedDate}
            onDateSelected={setSelectedDate}
        >
            {schedules?.map(schedule => (
                selectedSchedule?.id === schedule.id
                    ? <DetailedScheduleCalendarItem
                        key={schedule.id}
                        onArrivedClick={() => onArrivedClick(schedule)}
                        onModifyClick={() => showModifyScheduleFlow(schedule)}
                        onClick={() => setSelectedSchedule(null)}
                        topPositionBias={configuration.openingTime.hours}
                        schedule={schedule}
                        calculateLeftPosition={() => calculateLeftPosition(schedules, schedule, leftPositionBias())}
                        minuteHeight={configuration.minuteHeight}
                        itemWidth={detailedItemWidth()}
                    />
                    : <ScheduleCalendarItem
                        key={schedule.id}
                        onArrivedClick={() => onArrivedClick(schedule)}
                        onModifyClick={() => showModifyScheduleFlow(schedule)}
                        onClick={() => setSelectedSchedule(schedule)}
                        topPositionBias={configuration.openingTime.hours}
                        schedule={schedule}
                        calculateLeftPosition={() => calculateLeftPosition(schedules, schedule, leftPositionBias())}
                        minuteHeight={configuration.minuteHeight}
                        itemWidth={itemWidth()}
                    />
            ))}
        </SchedulesCalendar>
        <Button style={{
            position: 'fixed',
            bottom: '1rem',
            right: '1rem'
        }}
            variant="contained"
            onClick={showNewScheduleFlow}
        >
            Nuevo Turno
        </Button>
    </>
}

export const makeSchedulesCalendarHomeFlow = ({ showModifyScheduleFlow, showNewScheduleFlow, schedulesRepository, branchConfigurationRepository }) => () => {
    return <SchedulesCalendarHomeFlow
        showNewScheduleFlow={showNewScheduleFlow}
        showModifyScheduleFlow={showModifyScheduleFlow}
        repository={schedulesRepository}
        configurationProvider={branchConfigurationRepository}
    />
}
