export class InMemoryBranchesRepositoryDecorator {
    constructor(decoratee, cache) {
        this.decoratee = decoratee;
        this.cache = cache;
    }

    async getAll(business) {
        const cached = this.read(business);
        if (cached)
            return cached;
        const remote = await this.decoratee.getAll(business);
        this.store(remote, business);
        return remote;

    }

    async create(branch) {
        this.cache.reset()
        await this.decoratee.create(branch)
    }

    async update(branch) {
        this.cache.reset()
        await this.decoratee.update(branch)
    }

    store(branches, business) {
        this.cache.set(`branches_list_${business}`, branches);
    }

    read(business) {
        return this.cache.get(`branches_list_${business}`);
    }
}
