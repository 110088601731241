import { Add, Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionForm } from "../../Shared/Components/ActionForm/ActionForm";
import { ActionFormButtonsContainer } from "../../Shared/Components/ActionForm/ActionFormButtonsContainer";
import { ActionFormContent } from "../../Shared/Components/ActionForm/ActionFormContent";
import { ActionFormSection } from "../../Shared/Components/ActionForm/ActionFormSection";
import { ActionFormTextField } from "../../Shared/Components/ActionForm/ActionFormTextField";

export default function BusinessForm({ loading, onCancel, onSubmit, business }) {

    const [values, setValues] = useState({ name: '' })

    const [branches, setBranches] = useState([{
        name: '',
        address: '',
        phone_number: ''
    }])

    useEffect(() => {
        if (!business) return
        setValues({ ...business })
    }, [business])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit({
            ...values,
            branches
        })
    }

    const handleBranchUpdate = (index, val) => e => {
        const brs = [...branches]
        const br = brs[index]
        br[val] = e.target.value
        brs[index] = br
        setBranches(brs)
    }

    const formIsValid = values.name.length >= 3 && branches.length > 0 && branches.every(b => b.name.length > 2)
    const saveButtonTitle = loading ? 'Guardando' : 'Guardar'

    const BranchesFormSection = (
        <ActionFormSection title='Sucursales'>
            <ActionFormContent>
                {branches.map((branch, index) => (
                    <ActionFormSection title={`Sucursal #${index + 1}`}>
                        <ActionFormContent>
                            <ActionFormTextField
                                label='Nombre'
                                isRequired
                                placeholder='Nombre de la sucursal (min 3 caracteres)'
                                value={branch.name}
                                onChange={handleBranchUpdate(index, 'name')}
                            />
                            <ActionFormContent direction="horizontal">
                                <ActionFormTextField
                                    label='Telefono'
                                    placeholder='+1 555 5235'
                                    value={branch.phone_number}
                                    onChange={handleBranchUpdate(index, 'phone_number')}
                                />
                                <ActionFormTextField
                                    label='Direccion'
                                    placeholder='512th Ocean Drive Av.'
                                    value={branch.address}
                                    onChange={handleBranchUpdate(index, 'address')}
                                />
                            </ActionFormContent>
                            {index > 0 ? <IconButton onClick={e => {
                                e.preventDefault()
                                const brs = [...branches]
                                brs.splice(index, 1)
                                setBranches(brs)
                            }}>
                                <Delete />
                            </IconButton> : null}
                        </ActionFormContent>
                    </ActionFormSection>
                ))}
                <Button variant="outlined" startIcon={<Add />} className='text-field' onClick={(e) => {
                    e.preventDefault()
                    setBranches([...branches, {
                        name: '',
                        address: '',
                        phone_number: ''
                    }])
                }} >Agregar Sucursal</Button>

            </ActionFormContent>
        </ActionFormSection>
    )

    return (
        <ActionForm title={business ? 'Editar Empresa' : 'Nueva Empresa'} handleSubmit={handleSubmit}>
            <ActionFormContent>
                <ActionFormTextField
                    label='Nombre'
                    placeholder='Nombre de la empresa (min 3 caracteres)'
                    value={values.name}
                    onChange={handleChange('name')}
                    isRequired
                />
                {business ? null : BranchesFormSection}
            </ActionFormContent>
            <ActionFormButtonsContainer>
                <Button disabled={!formIsValid || loading} variant="contained" type='submit' color="primary">{saveButtonTitle}</Button>
                <Button className='form-button' onClick={(e) => {
                    e.preventDefault();
                    onCancel()
                }}>Cancelar</Button>
            </ActionFormButtonsContainer>
        </ActionForm>
    )
}
