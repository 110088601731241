import { Children, useState } from 'react';
import ListHeader from '../ListHeader';
import NonQueriableList from "../NonQueriable/NonQueriableList";
import './QueryableList.scss'

export default function QueryableList({
    emptyListView,
    emptyQueriedListView,
    onAdd,
    component = 'nav',
    onQueryChange,
    subheaders,
    children,
    searchFieldPlaceholder = 'Buscar...',
    style
}) {
    const [query, setQuery] = useState('')
    const hasItems = Children.count(children) > 0
    const emptyList = !hasItems && query.length === 0
    const emptyQueryResultsView = !hasItems && query.length > 0 ? emptyQueriedListView : null
    const emptyView = emptyList ? emptyListView : emptyQueryResultsView ?? null

    return (
        <div style={style}>
            <ListHeader
                onQueryChange={query => {
                    setQuery(query)
                    onQueryChange(query)
                }}
                placeholder={searchFieldPlaceholder}
                onAdd={onAdd}
            />
            <NonQueriableList
                emptyListView={emptyView}
                onAdd={onAdd}
                component={component}
                subheaders={subheaders}
            >
                {children}
            </NonQueriableList>
        </div>
    )
}
