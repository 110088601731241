import { Step, StepButton, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import { ClientSelectionStep } from "./ClientSelectionStep";
import { DateTimeSelectionStep } from "./DateTimeSelectionStep";
import { FinalStep } from "./FinalStep";
import { ServiceSelectionStep } from "./ServiceSelectionStep";

export function NewScheduleForm({ createClientWithDocNumber, preselectedClient, scheduleToEdit, schedules, branchConfiguration, onDateSelected, searchClients, filteredClients, searchServices, filteredServices, createSchedule }) {
    const [selectedClient, setSelectedClient] = useState(scheduleToEdit?.client ?? preselectedClient ?? null);
    const [selectedServices, setSelectedServices] = useState(scheduleToEdit?.services ?? []);
    const [selectedDate, setSelectedDate] = useState(scheduleToEdit?.starting_date ? new Date(scheduleToEdit?.starting_date) : new Date());
    const [activeStep, setActiveStep] = useState(scheduleToEdit ? 3 : 0);

    const configuration = {
        ...branchConfiguration,
        minuteHeight: 3,
    };

    useEffect(() => {
        if (preselectedClient) {
            setActiveStep(1);
            setSelectedClient(preselectedClient);
        }
    }, [preselectedClient]);

    function onCalendarDateSelected(date) {
        const newDate = new Date(date);
        newDate.setHours(selectedDate.getHours(), selectedDate.getMinutes());
        setSelectedDate(newDate);
        onDateSelected(newDate);
    }

    function onTimeHourSelected(time) {
        if (!time || isNaN(time)) {
            return;
        }
        const newDate = new Date(selectedDate);
        newDate.setHours(time.getHours(), time.getMinutes());
        setSelectedDate(newDate);
    }

    function onClientSelected(client) {
        setSelectedClient(client);
        setActiveStep(1);
    }

    function onServiceSelected(service) {
        if (selectedServices.find(s => s.id === service.id)) {
            setSelectedServices(selectedServices.filter(s => s.id !== service.id));
            return;
        }
        setSelectedServices([...selectedServices, service]);
    }

    const scheduleToBeCreated = () => {
        const schedule = {
            id: scheduleToEdit?.id ?? -1,
            client: selectedClient,
            starting_date: composeStartingDate(),
            ending_date: calculateEndingDate(composeStartingDate()),
            services: selectedServices,
        }
        return schedule;
    };

    function composeStartingDate() {
        const startingDate = new Date(selectedDate);
        startingDate.setHours(selectedDate.getHours(), selectedDate.getMinutes());
        return startingDate;
    }

    function calculateEndingDate(starting_date) {
        const endingDate = new Date(starting_date);
        var totalDuration = selectedServices
            .map(service => service.duration)
            .map(duration => parseInt(duration))
            .reduce((a, b) => a + b, 0);
        if (totalDuration === 0) {
            totalDuration = 30;
        };
        endingDate.setMinutes(endingDate.getMinutes() + totalDuration);
        return endingDate;
    }

    return <div style={{ maxWidth: 1200, width: '100%', margin: 'auto' }}>
        <Stepper style={{ padding: 16 }} activeStep={activeStep} alternativeLabel>
            <Step key={0}>
                <StepButton onClick={() => setActiveStep(0)}>{selectedClient?.name ?? 'Cliente'}</StepButton>
            </Step>
            <Step key={1}>
                <StepButton onClick={() => setActiveStep(1)}>{selectedServices.length > 0 ? selectedServices.map(s => s.displayable_name).join(', ') : 'Servicios'}</StepButton>
            </Step>
            <Step key={2}>
                <StepButton onClick={() => setActiveStep(2)}>{selectedDate?.toLocaleString() ?? 'Horario'}</StepButton>
            </Step>
            <Step key={3}>
                <StepButton onClick={() => setActiveStep(3)}>Confirmar</StepButton>
            </Step>
        </Stepper>
        <>
            {activeStep === 0 ?
                <ClientSelectionStep
                    createClientWithDocNumber={createClientWithDocNumber}
                    searchClients={searchClients}
                    filteredClients={filteredClients}
                    onClientSelected={onClientSelected}
                />
                : activeStep === 1 ? <ServiceSelectionStep
                    searchServices={searchServices}
                    filteredServices={filteredServices}
                    onServiceSelected={onServiceSelected}
                    selectedServices={selectedServices}
                    scheduleToBeCreated={scheduleToBeCreated}
                    onContinueClicked={() => setActiveStep(p => p + 1)}
                />
                    : activeStep === 2 ? <DateTimeSelectionStep
                        configuration={configuration}
                        schedules={schedules}
                        selectedDate={selectedDate}
                        onCalendarDateSelected={onCalendarDateSelected}
                        scheduleToBeCreated={scheduleToBeCreated}
                        onTimeHourSelected={onTimeHourSelected}
                        onDateConfirmed={() => setActiveStep(p => p + 1)}
                    /> :
                        <FinalStep
                            selectedClient={selectedClient}
                            selectedDate={selectedDate}
                            selectedServices={selectedServices}
                            modifyClient={() => setActiveStep(0)}
                            modifyServices={() => setActiveStep(1)}
                            modifyDate={() => setActiveStep(2)}
                            onConfirm={() => createSchedule(scheduleToBeCreated())}
                        />
            }
        </>
    </div>
}
