import { baseURL } from "../../Shared/Networking/constants"
import { jsonRequest } from "../../Shared/Networking/rest-client"

export default class RemoteServiceCategoriesRepository {
    constructor(business) {
        this.business = business
    }

    getUrl() {
     return `${baseURL}/businesses/${this.business}/service_categories`
    }

    async create(category) {
        return await jsonRequest(`${this.getUrl()}`, { method: 'POST', body: category })
    }

    async update(category) {
        return await jsonRequest(`${this.getUrl()}`, { method: 'PUT', body: category })
    }

    async getById(id) {
        return await jsonRequest(`${this.getUrl()}/${id}`).then(response => response.data)
    }

    async getAll() {
        return await jsonRequest(`${this.getUrl()}`).then(response => response.data)
    }
}
