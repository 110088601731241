import { useEffect } from 'react'
import NonQueryableList from '../../Shared/Components/Lists/NonQueriable/NonQueriableList';
import PageContainer from '../../Shared/Components/PageContainer/PageContainer';
import ListItem from '../../Shared/Components/Lists/ListItem';
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView';

export default function ServiceCategoriesList({ categories, onLoad, onCategorySelected, onCreateCategory }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={onCreateCategory} />
    return (
        <PageContainer>
            <NonQueryableList
                onAdd={onCreateCategory}
                emptyListView={emptyView('Crea la primer categoria')}
            >
                {categories.map((category) => (
                    <ListItem
                        key={category.id}
                        columns={[category.name]}
                        onItemSelected={() => onCategorySelected(category)}
                    />
                ))}
            </NonQueryableList>
        </PageContainer>
    )
}
