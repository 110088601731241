import { Button } from '@mui/material';
import React from 'react';

export function AuthenticationNavbarButton({ isUserLogged, doLogout, doLogin }) {
    const authButtonTitle = isUserLogged ? 'Log out' : 'Log In';
    const authButtonClick = (event) => {
        event.preventDefault();
        if (isUserLogged)
            doLogout();

        else
            doLogin();
    };

    return (
        <Button onClick={authButtonClick} color="inherit">
            {authButtonTitle}
        </Button>
    );
}
