import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export function ActionFormDateField({ label, value, onChange, variant = 'outlined', openTo = 'year', isRequired = false }) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
            className='text-field'
            label={label}
            openTo={openTo}
            value={value}
            onChange={onChange}
            error={false}
            inputFormat="dd MMM yyyy"
            disableMaskedInput
            views={['year', 'month', 'day']}
            renderInput={props => <TextField
                {...props}
                variant={variant}
                required={isRequired} />} />
    </LocalizationProvider>
}
