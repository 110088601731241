import { useEffect } from 'react'
import QueryableList from '../../Shared/Components/Lists/Queryable/QueryableList';
import PageContainer from '../../Shared/Components/PageContainer/PageContainer';
import ListItem from '../../Shared/Components/Lists/ListItem';
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView';

export default function ServicesList({ onQueryChange, services, onLoad, onServiceSelected, onCreateService, noCategoriesErrorMessage }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={noCategoriesErrorMessage ?? title}
            onActionClick={onCreateService} />
    return (
        <PageContainer>
            <QueryableList
                onQueryChange={onQueryChange}
                onAdd={onCreateService}
                subheaders={['Nombre', 'Codigo', 'Categoria', 'Duracion']}
                emptyListView={emptyView('Crea el primer servicio')}
                emptyQueriedListView={emptyView('El servicio no existe')}
            >
                {services.map((service) => (
                    <ListItem
                        key={service.id}
                        columns={[
                            service.displayable_name,
                            service.name,
                            service.category_name,
                            `${service.duration}min.`
                        ]}
                        onItemSelected={() => onServiceSelected(service)}
                    />
                ))}
            </QueryableList>
        </PageContainer>
    )
}
