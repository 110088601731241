import { useEffect } from 'react';
import QueryableList from '../../Shared/Components/Lists/Queryable/QueryableList.js';
import PageContainer from '../../Shared/Components/PageContainer/PageContainer.js';
import ListItem from '../../Shared/Components/Lists/ListItem.js';
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView.js';

export default function ClientsList({ onLoad, onQueryChange, clients, makeScheduleForClient, onClientSelected, createClient }) {
    useEffect(onLoad, [onLoad])

    const contactDetails = (client) => {
        const details = client.contact_details
        return details && details.length ? details[0].value : ''
    }
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={createClient} />
    return (
        <PageContainer>
            <QueryableList
                onQueryChange={onQueryChange}
                onAdd={createClient}
                subheaders={['Apellido', 'Nombre', 'DNI', 'Contacto']}
                emptyListView={emptyView('Agrega el primer cliente')}
                emptyQueriedListView={emptyView('El cliente no existe')}
            >
                {clients.map((client) => (
                    <ListItem
                        key={client.id}
                        columns={[client.last_name, client.name, client.identification?.number, contactDetails(client)]}
                        onItemSelected={() => onClientSelected(client)}
                    />
                ))}
            </QueryableList>
        </PageContainer>
    );
}
