import { Button } from '@mui/material';
import React from 'react';
import { DarkMode, LightMode } from '@mui/icons-material';

export function DarkModeNavbarButton({ theme, onChangeThemeClick }) {

    return (
        <Button onClick={onChangeThemeClick} color='inherit'>
            {(theme === 'dark' ? <LightMode /> : <DarkMode />)}
        </Button>
    );
}
