import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export function CurrentTimeLine({ isVisible, minuteHeight, startTimeInMinutes, endTimeInMinutes }) {
  const [now, setNow] = useState(new Date());
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const totalMinutes = hours * 60 + minutes;

  const isInBounds = totalMinutes >= startTimeInMinutes && totalMinutes < endTimeInMinutes;

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if ((!isVisible || !isInBounds)) {
    return null;
  }
  const formattedHourAndMinutes = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  const lineYPosition = (totalMinutes - startTimeInMinutes) * minuteHeight;
  return (
    <>
      <div style={{
        display: isInBounds ? 'block' : 'none',
        position: 'absolute',
        top: lineYPosition,
        width: '100%',
        borderTop: '2px solid #f00',
        height: 1,
      }} />
      <Typography
        style={{
          position: 'absolute',
          top: lineYPosition - 14,
          backgroundColor: '#f00',
          border: '1px solid #f00',
          borderRadius: '8px',
          padding: '4px',
        }}
        variant='body1'
      >{formattedHourAndMinutes}</Typography>
    </>
  );
}
