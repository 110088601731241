import DebouncedSearchField from "../SearchField/Debounced/DebouncedSearchField";

export default function ListHeader({ onQueryChange, placeholder = 'Buscar...' }) {
    return (
        <div className='header'>
            <DebouncedSearchField
                variant='outlined'
                onChange={onQueryChange}
                placeholder={placeholder}
            />
        </div>
    )
}

