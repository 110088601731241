import { ListSubheader as MatListSubheader, Typography } from '@mui/material'

export default function ListSubheader({ columns }) {
    function className(index) {
        const classes = ['list-item-content']
        if (index > 2)
            classes.push('non-essential')
        return classes.join(' ')
    }
    const width = 100 / columns.length
    return (
        <MatListSubheader className='list-item' style={{backgroundColor:'inherit'}}>
            {columns.map((col, index) =>
                <Typography key={index} className={className(index)} style={{ width: `${width}%` }}>{col}</Typography>
            )}
        </MatListSubheader>
    )
}
