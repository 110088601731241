import { makeClientsList, makeNewClientFormFlow } from "../Clients/DI/factory";
import { RemoteClientsRepository } from "../Clients/Infrastructure/clients-repository";
import { makeSignupForm } from "../UserAuthentication/Signup/DI/factory";
import { makeLoginForm } from "../UserAuthentication/Login/DI/factory";
import { Route, useLocation } from "wouter";
import { makeEmployeesList } from "../Employees/DI/factory";
import { makeBusinessesList } from "../Businesses/DI/factory";
import { makeBranchesList } from "../Branches/DI/factory";
import { makeBranchSelection } from "../BranchSelection/DI/factory";
import RemoteServiceCategoriesRepository from "../ServiceCategories/Infrastructure/RemoteServiceCategoriesRepository";
import { InMemoryServiceCategoriesRepositoryDecorator } from "../ServiceCategories/Infrastructure/InMemoryServiceCategoriesRepositoryDecorator";
import LRUCache from "lru-cache";
import { makePriceListDetail } from "../ServicePriceLists/DI/factory";
import { RemotePriceListsRepository } from "../ServicePriceLists/Infrastructure/RemotePriceListsRepository";
import { getServicesWithPrice } from "../ServicePriceLists/DI/getServicesWithPrice";
import { RemoteServicesRepository } from "../Services/Infrastructure/RemoteServicesRepository";
import { bulkCreatePrices, getAllPrices } from "../ServicePriceLists/Infrastructure/PricesRepository";
import { InMemoryServicesRepositoryDecorator } from "../Services/Infrastructure/InMemoryServicesRepositoryDecorator";
import { ServicesFlowFactory } from "./Flows/ServicesFlowFactory";
import { ServiceCategoriesFlowFactory } from "./Flows/ServiceCategoriesFlowFactory";
import { PriceListFlowFactory } from "./Flows/PriceListFlowFactory";
import { DrawerMenu } from "./DrawerMenu";
import { makeSchedulesCalendarHomeFlow } from "../SchedulesHomeScreen/di/factory";
import { makeEditScheduleFormFlow, makeNewScheduleFormFlow } from "../NewScheduleForm/di/factory";
import { ApiSchedulesRepository } from "../SchedulesHomeScreen/di/ApiSchedulesRepository";
import { DefaultConfigurationProvider } from "../NewScheduleForm/di/DefaultConfigurationProvider";

export default function App({ onUserLoggedIn, selectedBranch, selectedBusiness }) {
  const serviecsRpository = new InMemoryServicesRepositoryDecorator(
    new RemoteServicesRepository(selectedBusiness),
    new LRUCache({ maxAge: 1000 * 60 * 60 })
  )
  const clientsRepository = new RemoteClientsRepository(selectedBusiness);
  const priceListsRepository = new RemotePriceListsRepository(selectedBusiness);
  const serviceCategorieRepository = new InMemoryServiceCategoriesRepositoryDecorator(
    new RemoteServiceCategoriesRepository(selectedBusiness),
    new LRUCache({ maxAge: 1000 * 60 * 60 })
  )
  const schedulesRepository = new ApiSchedulesRepository(selectedBusiness, selectedBranch)
  const branchConfigurationRepository = new DefaultConfigurationProvider()

  const setLocation = useLocation()[1]
  const unauthErrorHandler = () => setLocation('/login')

  return (
    <>
      <Route path="/schedules/new" component={newScheduleFlow()} />
      <Route path="/schedules/new/clientId/:clientId" component={newScheduleFlow()} />
      <Route path="/schedules/:scheduleId/modify" component={modifyScheduleFlow()} />
      <Route path="/home" component={schedulesCalendarFlow()} />
      <Route path="/services" component={servicesFlow()} />
      <Route path="/service-categories" component={serviceCategoriesFlow()} />
      <Route path="/services/price-lists" component={priceListFlow()} />
      <Route path="/services/price-lists/:priceListId" component={pricesForServicesFlow()} />
      <Route path="/employees" component={employeesFlow()} />
      <Route path="/clients" component={clientsFlow()} />
      <Route path="/clients/new/:preloadedDocNumber" component={clientFormFlow()} />
      <Route path="/clients/new" component={clientFormFlow()} />
      <Route path="/businesses" component={makeBusinessesList()} />
      <Route path="/branches" component={branchesFlow()} />
      <Route path="/branch-selection" component={branchSelectionFlow()} />
      <Route path="/register" component={signupFlow()} />
      <Route path="/login" component={loginFlow()} />
      <Route path="/menu" component={DrawerMenu} />
      <Route path="/" component={schedulesCalendarFlow()} />
    </>
  );

  function clientsFlow() {
    return makeClientsList({
      repository: clientsRepository,
      makeScheduleForClient: (client) => {
        setLocation(`/schedules/new/clientId/${client.id}`)
      }
    });
  }
  
  function clientFormFlow() {
    return props => makeNewClientFormFlow({
      repository: clientsRepository,
      documentNumber: props?.params?.preloadedDocNumber,
      onClientCreated: () => setLocation('/schedules/new')
    })();
  }

  function loginFlow() {
    return makeLoginForm(onUserLoggedIn);
  }

  function signupFlow() {
    return makeSignupForm(onUserLoggedIn);
  }

  function branchSelectionFlow() {
    return makeBranchSelection();
  }

  function branchesFlow() {
    return makeBranchesList({ business: selectedBusiness });
  }

  function employeesFlow() {
    return makeEmployeesList({
      unauthErrorHandler,
      business: selectedBusiness,
      branch: selectedBranch
    });
  }

  function modifyScheduleFlow() {
    return (props) => makeEditScheduleFormFlow({
      scheduleIdToEdit: props.params.scheduleId,
      goToHome: () => setLocation('/home'),
      servicesRepository: serviecsRpository,
      clientsRepository: clientsRepository,
      createClientWithDocNumber: docNumber => setLocation(`/clients/new/${docNumber}`),
      schedulesRepository: schedulesRepository,
      branchConfigurationRepository: branchConfigurationRepository
    })()
  }

  function newScheduleFlow() {
    return (props) => makeNewScheduleFormFlow({
      goToHome: () => setLocation('/home'),
      createClientWithDocNumber: docNumber => setLocation(`/clients/new/${docNumber}`),
      preselectedClientId: props?.params?.clientId,
      servicesRepository: serviecsRpository,
      clientsRepository: clientsRepository,
      schedulesRepository: schedulesRepository,
      branchConfigurationRepository: branchConfigurationRepository
    })();
  }

  function schedulesCalendarFlow() {
    return makeSchedulesCalendarHomeFlow({
      showModifyScheduleFlow: (schedule) => setLocation(`/schedules/${schedule.id}/modify`),
      showNewScheduleFlow: () => setLocation('/schedules/new'),
      schedulesRepository: schedulesRepository,
      branchConfigurationRepository: branchConfigurationRepository
    });
  }

  function pricesForServicesFlow() {
    const servicesWithPrice = getServicesWithPrice(
      selectedBusiness,
      () => serviecsRpository.getAll(''),
      getAllPrices
    );
    return (props) => makePriceListDetail({
      unauthErrorHandler,
      getServicesWithPrice: () => servicesWithPrice(props.params.priceListId),
      getPriceList: () => priceListsRepository
        .getAll()
        .then(list => list.find(l => l.id === props.params.priceListId)),
      bulkCreatePrices: prices => bulkCreatePrices(selectedBusiness, props.params.priceListId, prices)
    })();
  }

  function priceListFlow() {
    return new PriceListFlowFactory({
      unauthErrorHandler,
      priceListsRepository,
      setLocation
    }).make()
  }

  function serviceCategoriesFlow() {
    return new ServiceCategoriesFlowFactory({
      serviceCategorieRepository,
      unauthErrorHandler,
    }).make()
  }

  function servicesFlow() {
    return new ServicesFlowFactory({
      serviecsRpository,
      serviceCategorieRepository,
      unauthErrorHandler,
      setLocation
    }).makeServicesList();
  }
}
