import { Button, Typography } from "@mui/material";
import { DefaultEmptyView } from "../../Shared/Components/DefaultEmptyView";
import ListItem from "../../Shared/Components/Lists/ListItem";
import QueryableList from "../../Shared/Components/Lists/Queryable/QueryableList";
import NonQueriableList from "../../Shared/Components/Lists/NonQueriable/NonQueriableList";
import { prettyDuration } from "../../Shared/prettyLongDate";

export function ServiceSelectionStep({ onServiceSelected, selectedServices, filteredServices, searchServices, onContinueClicked }) {
    function selectedMarker(service) {
        if (selectedServices.find(s => s.id === service.id)) {
            return ' ✔';
        }
        return '';
    }

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <div style={{
            gap: 16,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'start',
            flexWrap: 'wrap',
        }}>
            <div style={{ maxWidth: 500, width: '100%', minWidth: 300 }}>
                <QueryableList
                    searchFieldPlaceholder="Buscar Servicio"
                    onQueryChange={searchServices}
                    subheaders={['Nombre', 'Precio']}
                >
                    {filteredServices.map((service) => <ListItem
                        key={service.id}
                        columns={[`${service.displayable_name}${selectedMarker(service)}`, `${service.duration} min`]}
                        onItemSelected={() => onServiceSelected(service)} />)}
                </QueryableList>
            </div>
            <div style={{
                maxWidth: 350, width: '40%', minWidth: 270,
                border: '1px solid #ccc',
                borderRadius: 8,
                padding: 16,
                gap: 16,
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography

                    variant='h5'>Servicios Seleccionados</Typography>
                <NonQueriableList
                    emptyListView={<DefaultEmptyView title={"No hay servicios seleccionados"} />}
                    subheaders={['Nombre', 'Duracion']}>
                    {selectedServices.map((service) => (
                        <ListItem
                            key={service.id}
                            columns={[service.displayable_name, `${service.duration} min`]}
                            onItemSelected={() => onServiceSelected(service)} />
                    ))}

                </NonQueriableList>
                {selectedServices.length > 0 && <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 8,
                    flexWrap: 'wrap'
                }}>
                    <Typography variant='body1'>Duracion Total</Typography>
                    <Typography variant='body2'>
                        {prettyDuration(selectedServices.map(s => parseInt(s.duration)).reduce((a, b) => a + b))}
                    </Typography>
                </div>}
                <Button
                    style={{ width: '100%' }}
                    onClick={onContinueClicked}
                    variant='contained'
                >Continuar</Button>
            </div>
        </div>
    </div>;
}
