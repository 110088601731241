import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';

export function FullScreenDialog({ open, children, onClose = () => { } }) {
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    return <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        {children}
    </Dialog>;
}
