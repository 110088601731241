import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionForm } from "../../../Shared/Components/ActionForm/ActionForm";
import { ActionFormButtonsContainer } from "../../../Shared/Components/ActionForm/ActionFormButtonsContainer";
import { ActionFormContent } from "../../../Shared/Components/ActionForm/ActionFormContent";
import { ActionFormOptionsField } from "../../../Shared/Components/ActionForm/ActionFormOptionsField";
import { ActionFormSection } from "../../../Shared/Components/ActionForm/ActionFormSection";
import { ActionFormTextField } from "../../../Shared/Components/ActionForm/ActionFormTextField";

export default function ServiceForm({ onCancel, onSubmit, service, categories }) {
    const [loading, setLoading] = useState(false)

    const categoriesOptions = categories.map(c => { return { value: c.id, name: c.name } })
    const [values, setValues] = useState({
        name: '',
        displayable_name: '',
        duration: '',
        category_id: '',
    })
    useEffect(() => {
        if (!service) {
            return
        }
        setValues({
            ...service,
            category_id: service.category_id
        })
    }, [service])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleCategoryChange = (event) => {
        const existingValues = { ...values }
        existingValues.category_id = event.target.value
        setValues(existingValues)
    }

    const defaultCategory = categoriesOptions[0].value
    const setDefaultCategory = () => {
        const existingValues = { ...values }
        if (existingValues.category_id === '') {
            existingValues.category_id = defaultCategory
            return existingValues
        }
        return existingValues
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        const valuesToSubmit = setDefaultCategory()
        setLoading(true)
        onSubmit(valuesToSubmit)
    }

    const formIsValid = values.name.length >= 3 && values.displayable_name.length >= 3 && values.duration > 0 && /^-?\d+$/.test(values.duration)

    const saveButtonTitle = loading ? 'Guardando' : 'Guardar'
    return (
        <ActionForm title={service ? 'Editar Servicio' : 'Nuevo Servicio'} handleSubmit={handleSubmit}>
            <ActionFormContent>
                <ActionFormTextField
                    label='Codigo (min 3 caracteres)'
                    value={values.name}
                    isRequired
                    onChange={handleChange('name')}
                />
                <ActionFormTextField
                    label='Nombre'
                    value={values.displayable_name}
                    isRequired
                    onChange={handleChange('displayable_name')}
                />
                <ActionFormTextField
                    label='Duracion (minutos)'
                    isRequired
                    value={values.duration}
                    onChange={handleChange('duration')}
                />
            </ActionFormContent>
            <ActionFormSection title='Categoria'>
                <ActionFormOptionsField
                    label='Seleccionar'
                    options={categoriesOptions}
                    defaultValue={defaultCategory}
                    value={values.category_id}
                    isRequired
                    onChange={handleCategoryChange}
                />
            </ActionFormSection>
            <ActionFormButtonsContainer>
                <Button disabled={!formIsValid || loading} variant="contained" type='submit' color="primary">{saveButtonTitle}</Button>
                <Button className='form-button' onClick={(e) => {
                    e.preventDefault();
                    onCancel()
                }}>Cancelar</Button>
            </ActionFormButtonsContainer>
        </ActionForm>
    )
}
