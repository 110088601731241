import { ThemeProvider } from '@emotion/react';
import { Button, createTheme, Paper, SwipeableDrawer } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'wouter';
import App from './App';
import { isUserLogged, logOut } from '../Shared/Session/session-utils';
import NavBar from '../Shared/Components/NavBar/NavBar';
import { DarkModeNavbarButton } from '../Shared/Components/NavBar/DarkModeNavbarButton';
import { AuthenticationNavbarButton } from '../Shared/Components/NavBar/AuthenticationNavbarButton';
import { getSelectedBranch, getSelectedBranchName, getSelectedBusiness, getSelectedBusinessName } from "../BranchSelection/DI/BranchAndBusinessSelection";
import { DrawerMenu } from './DrawerMenu';

export default function ThemedApp() {

    const [mode, setMode] = useState('dark');
    const colorMode = () => {
        setMode((prevMode) => prevMode === 'light' ? 'dark' : 'light')
    };

    const theme = useMemo(() => createTheme({ palette: { mode } }), [mode]);

    useEffect(() => {
        document.body.style.backgroundColor = theme.palette.background.default;
    }, [theme]);

    const [userLogged, setIsUserLogged] = useState(isUserLogged())
    const setLocation = useLocation()[1]
    const [selectedBusiness] = useState(getSelectedBusiness())
    const [selectedBranch] = useState(getSelectedBranch())
    const [selectedBusinessName] = useState(getSelectedBusinessName())
    const [selectedBranchName] = useState(getSelectedBranchName())
    const [drawerOpened, setDrawerOpened] = useState(false)

    const onUserLoggedIn = () => {
        setIsUserLogged(true)
    }

    const handleLogout = () => {
        logOut()
        setIsUserLogged(false)
        setLocation('/login')
    }

    return (
        <ThemeProvider theme={theme} >
            <Paper style={{ height: '100%' }} elevation={0} square>
                <NavBar
                    onMenuClick={() => setDrawerOpened(true)}
                    title={`Schedules Manager`}
                    subtitle={selectedBranchName ? `${selectedBusinessName} - ${selectedBranchName}` : null}
                    onTitleClick={() => setLocation('/home')}
                >
                    <Button onClick={() => setLocation('/services')}
                    >Servicios</Button>
                    <Button onClick={() => setLocation('/clients')}
                    >Clientes</Button>
                    <Button variant='outlined' onClick={() => setLocation('/schedules/new')}
                    >Nuevo Turno</Button>
                    <Button variant='outlined' onClick={() => setLocation('/home')}
                    >Agenda</Button>

                </NavBar>
                <SwipeableDrawer
                    open={drawerOpened}
                    onClose={() => setDrawerOpened(false)}
                    onOpen={() => setDrawerOpened(true)}
                >
                    <DrawerMenu closeDrawer={() => setDrawerOpened(false)}>
                        <DarkModeNavbarButton
                            theme={mode}
                            onChangeThemeClick={colorMode}
                        />
                        <AuthenticationNavbarButton
                            isUserLogged={userLogged}
                            doLogin={() => setLocation('/login')}
                            doLogout={() => handleLogout()}
                        />
                    </DrawerMenu>
                </SwipeableDrawer>
                <App
                    onUserLoggedIn={onUserLoggedIn}
                    selectedBusiness={selectedBusiness}
                    selectedBranch={selectedBranch}
                />
            </Paper>
        </ThemeProvider>
    )
}

