import { Typography } from "@mui/material";
import { CurrentTimeLine } from "./CurrentTimeLine";

export function HourAndMinuteRows({ openingTime, closingTime, minuteHeight, showCurrentTimeLine }) {
  const hourRowStyle = {
    height: (15 * minuteHeight) - 2,
    borderTop: '2px solid #ccc4',
  };
  const minutesRowStyle = {
    height: (15 * minuteHeight) - 1,
    borderTop: '1px solid #ccc4',
  };
  const allHoursToDisplay = Array.from({ length: closingTime.hours - openingTime.hours }, (_, i) => i + openingTime.hours);
  return (
    <div>
      {allHoursToDisplay.map(hour => (
        <div key={hour}>
          <Typography variant='h5' style={hourRowStyle}>{`${hour}:00`}</Typography>
          <Typography variant='body2' style={minutesRowStyle}>{`${hour}:15`}</Typography>
          <Typography variant='h6' style={minutesRowStyle}>{`${hour}:30`}</Typography>
          <Typography variant='body2' style={minutesRowStyle}>{`${hour}:45`}</Typography>
        </div>
      ))}
      <CurrentTimeLine
        isVisible={showCurrentTimeLine}
        minuteHeight={minuteHeight}
        startTimeInMinutes={openingTime.hours * 60}
        endTimeInMinutes={closingTime.hours * 60 + closingTime.minutes}
      />
    </div>
  );
}
