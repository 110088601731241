
export const selectBranch = (business, branch) => {
    localStorage.setItem('selected-business', business.id)
    localStorage.setItem('selected-business-name', business.name)
    localStorage.setItem('selected-branch', branch.id)
    localStorage.setItem('selected-branch-name', branch.name)
}
export const getSelectedBranch = () => localStorage.getItem('selected-branch')
export const getSelectedBranchName = () => localStorage.getItem('selected-branch-name')
export const getSelectedBusiness = () => localStorage.getItem('selected-business')
export const getSelectedBusinessName = () => localStorage.getItem('selected-business-name')
