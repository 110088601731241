export function prettyLongDate(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const titleCase = (str) => str[0].toUpperCase() + str.slice(1);
  return titleCase(date.toLocaleDateString('es-ES', options));
}

export function prettyTime(date) {
  const options = { hour: 'numeric', minute: 'numeric' };
  return date.toLocaleTimeString('es-ES', options);
}

export function prettyDuration(durationInMinutes) {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  if (hours === 0) {
    return `${minutes} minuto${minutes === 1 ? '' : 's'}`;
  }
  if (minutes === 0) {
    return `${hours} hora${hours === 1 ? '' : 's'}`;
  }
  return `${hours} hora${hours === 1 ? '' : 's'} y ${minutes} minuto${minutes === 1 ? '' : 's'}`;
}