export class InMemoryServicesRepositoryDecorator {
    constructor(decoratee, cache) {
        this.decoratee = decoratee;
        this.cache = cache;
    }

    async getAll() {
        const cached = this.read();
        if (cached)
            return cached;
        const remote = await this.decoratee.getAll();
        this.store(remote);
        return remote;

    }

    async create(service) {
        this.cache.reset()
        await this.decoratee.create(service)
    }

    async update(service) {
        this.cache.reset()
        await this.decoratee.update(service)
    }

    store(services) {
        this.cache.set('services_list', services);
    }

    read() {
        return this.cache.get('services_list');
    }
}
