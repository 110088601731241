import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionForm } from "../../../Shared/Components/ActionForm/ActionForm";
import { ActionFormButtonsContainer } from "../../../Shared/Components/ActionForm/ActionFormButtonsContainer";
import { ActionFormContent } from "../../../Shared/Components/ActionForm/ActionFormContent";
import { ActionFormTextField } from "../../../Shared/Components/ActionForm/ActionFormTextField";

export default function PriceListForm({ onCancel, onSubmit, pricelist }) {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        name: '',
    })
    useEffect(() => {
        if (!pricelist) {
            return
        }
        setValues({
            ...pricelist
        })
    }, [pricelist])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        onSubmit(values)
    }

    const formIsValid = values.name.length >= 3

    const saveButtonTitle = loading ? 'Guardando' : 'Guardar'
    return (
        <ActionForm title={pricelist ? 'Editar Lista de Precios' : 'Nueva Lista de Precios'} handleSubmit={handleSubmit}>
            <ActionFormContent>
                <ActionFormTextField
                    label='Nombre'
                    value={values.name}
                    isRequired
                    onChange={handleChange('name')}
                />
            </ActionFormContent>
            <ActionFormButtonsContainer>
                <Button disabled={!formIsValid || loading} variant="contained" type='submit' color="primary">{saveButtonTitle}</Button>
                <Button className='form-button' onClick={(e) => {
                    e.preventDefault();
                    onCancel()
                }}>Cancelar</Button>
            </ActionFormButtonsContainer>
        </ActionForm>
    )
}
