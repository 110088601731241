import React, { useEffect } from 'react'
import ListItem from '../../Shared/Components/Lists/ListItem'
import NonQueriableList from '../../Shared/Components/Lists/NonQueriable/NonQueriableList'
import PageContainer from '../../Shared/Components/PageContainer/PageContainer'
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView'

export default function BranchesList({ onSelected, branches, onLoad, onCreateBranch }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={onCreateBranch} />
    return (
        <PageContainer>
            <NonQueriableList
                emptyListView={emptyView('Crea tu primer negocio')}
                onAdd={onCreateBranch}
                subheaders={['Nombre', 'Direccion', 'Telefono']}
            >
                {branches.map((branch) => (
                    <ListItem
                        key={branch.id}
                        columns={[branch.name, branch.address, branch.phone_number]}
                        onItemSelected={() => onSelected(branch)}
                    />
                ))}
            </NonQueriableList>
        </PageContainer>
    )
}
