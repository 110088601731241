import { useLocation } from "wouter";
import NonQueriableList from "../Shared/Components/Lists/NonQueriable/NonQueriableList";
import ListItem from "../Shared/Components/Lists/ListItem";
import { Divider } from "@mui/material";

export function DrawerMenu({ closeDrawer, children }) {
  const setLocation = useLocation()[1];
  function navigateAndClose(path) {
    closeDrawer();
    setLocation(path);
  }
  return (<div style={{
    width: 300
  }}>
    <NonQueriableList subheaders={["Turnos"]}>
      <ListItem
        onItemSelected={() => {
          navigateAndClose('/home')
        }}
        columns={['Agenda']} />
      <ListItem
        onItemSelected={() => {
          navigateAndClose('/schedules/new')
        }}
        columns={['Nuevo Turno']} />
      <Divider />
      <NonQueriableList subheaders={["Clientes"]}>
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/clients')
          }}
          columns={['Lista de Clientes']} />
        <Divider />
      </NonQueriableList>
      <NonQueriableList subheaders={['Servicios']}>
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/services')
          }}
          columns={['Lista de servicios']} />
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/service-categories')
          }}
          columns={['Categories']} />
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/services/price-lists')
          }}
          columns={['Listas de precios']} />
      </NonQueriableList>
      <ListItem
        onItemSelected={() => {
          navigateAndClose('/employees')
        }}
        columns={['Empleados']} />
      <NonQueriableList subheaders={['Mi Negocio']}>
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/businesses')
          }}
          columns={['Negocios']} />
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/branches')
          }}
          columns={['Sucursales']} />
      </NonQueriableList>
      <NonQueriableList subheaders={['Configuracion']}>
        <ListItem
          onItemSelected={() => {
            navigateAndClose('/branch-selection')
          }}
          columns={['Seleccionar Sucursal']} />
      </NonQueriableList>
    </NonQueriableList>
    {children}
  </div>);
}
