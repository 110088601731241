import { Typography } from "@mui/material";
import { prettyLongDate } from "../../Shared/prettyLongDate";
import { ScheduleCalendarDatePicker } from "./ScheduleCalendarDatePicker";

export function SchedulesCalendarHeader({ headerContent, selectedDate, onDateSelected }) {  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem',
        gap: '1rem',
        flexWrap: 'wrap',
      }}
    >
      <Typography variant='h6'>
        {prettyLongDate(selectedDate)}
      </Typography>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <ScheduleCalendarDatePicker
          selected={selectedDate}
          onDateSelected={onDateSelected} />
        {headerContent}
      </div>
    </div>
  );
}
