import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class RemoteBusinessesRepository {
    url = `${baseURL}/businesses`
    async getAll() {
        return await jsonRequest(`${this.url}`).then(response => response.data)
    }

    async create(business) {
        return await jsonRequest(`${this.url}`, { method: 'POST', body: business })
    }

    async update(business) {
        return await jsonRequest(`${this.url}`, { method: 'PUT', body: business })
    }
}
