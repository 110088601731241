import { useCallback, useState } from "react";
import PriceListsList from "../Presentation/List/PriceListsList";
import PriceListForm from "../Presentation/Form/PriceListForm";
import { FullScreenDialog } from "../../Shared/Components/FullScreenDialog";
import PriceListDetails from "../Presentation/Detail/PriceListDetails";

export const makePriceListsList = ({ unauthErrorHandler, getAllPriceLists, repository, onPriceListSelected }) => () => {
    const [priceLists, setPriceLists] = useState([])
    const [newPriceListDialogOpen, setNewPriceListDialogOpen] = useState(false)

    const loadPriceLists = useCallback(
        () => getAllPriceLists()
            .then(setPriceLists)
            .catch(e => {
                if (e.status === 401) unauthErrorHandler()
            }),
        [])

    return <>
        <PriceListsList
            onLoad={loadPriceLists}
            pricelists={priceLists}
            onPriceListSelected={onPriceListSelected}
            onCreatePriceList={() => setNewPriceListDialogOpen(true)}
        />
        <FullScreenDialog open={newPriceListDialogOpen}>
            <PriceListForm
                onSubmit={s => {
                    repository.create(s)
                        .then(loadPriceLists)
                        .then(() => setNewPriceListDialogOpen(false))
                }}
                onCancel={() => setNewPriceListDialogOpen(false)}
            />
        </FullScreenDialog>
    </>
}

export const makePriceListDetail = ({ unauthErrorHandler, getServicesWithPrice, getPriceList, bulkCreatePrices }) => () => {
    const [prices, setPrices] = useState([])
    const [priceList, setPriceList] = useState(null)
    const loadPrices = useCallback(
        () => {
            getServicesWithPrice()
                .then(setPrices)
                .then(getPriceList)
                .then(setPriceList)
                .catch(e => {
                    if (e.status === 401) unauthErrorHandler()
                })
        }, []
    )

    const onSaveChanges = (prices) => {
        bulkCreatePrices(prices)
        .then(() => loadPrices())
    }

    return <PriceListDetails
        onLoad={loadPrices}
        priceListName={priceList?.name}
        prices={prices}
        onSaveChanges={onSaveChanges}
    />
}
