export class InMemoryEmployeesRepositoryDecorator {
    constructor(decoratee, cache) {
        this.decoratee = decoratee;
        this.cache = cache;
    }

    async getAll() {
        const cached = this.read();
        if (cached)
            return cached;
        const remote = await this.decoratee.getAll();
        this.store(remote);
        return remote;

    }

    async create(employee) {
        this.cache.reset()
        await this.decoratee.create(employee)
    }

    async update(employee) {
        this.cache.reset()
        await this.decoratee.update(employee)
    }

    store(employees) {
        this.cache.set('employees_list', employees);
    }

    read() {
        return this.cache.get('employees_list');
    }
}
