import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import NewClientForm from './NewClientForm.js';

export function ClientFormDialog({ loading, open, onSubmit, onCancel, client, makeScheduleForClient }) {
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    return <Dialog fullScreen={fullScreen} open={open} onClose={onCancel}>
        <NewClientForm loading={loading} onSubmit={onSubmit} onCancel={onCancel} client={client} scheduleAppointment={makeScheduleForClient}/>
    </Dialog>
}
