import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import ThemedApp from './MainApp/ThemedApp';


ReactDOM.render(
  <React.StrictMode>
    <ThemedApp />
  </React.StrictMode>,
  document.getElementById('root')
);
