import React from 'react';
import { FullScreenDialog } from '../../../Shared/Components/FullScreenDialog';
import ServiceCategoryForm from './ServiceCategoryForm';

export function ServiceCategoryFormDialog({ open, onSubmit, onCancel, category }) {
    return <FullScreenDialog open={open}>
        <ServiceCategoryForm onSubmit={onSubmit} onCancel={onCancel} category={category} />
    </FullScreenDialog>
}

