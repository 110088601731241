import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class RemoteBranchesRepository {
    constructor(selectedBusiness) {
        this.selectedBusiness = selectedBusiness
    }

    async getAll(business) {
        const url = `${baseURL}/businesses/${business ?? this.selectedBusiness}/branches`
        return await jsonRequest(`${url}`).then(response => response.data)
    }

    async create(branch) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/branches`
        return await jsonRequest(`${url}`, { method: 'POST', body: branch })
    }

    async update(branch) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/branches`
        return await jsonRequest(`${url}`, { method: 'PUT', body: branch })
    }
}
