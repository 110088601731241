import { useCallback, useState } from "react";
import ServicesList from "../Presentation/ServicesList";

import { ServiceFormDialog } from "../Presentation/Form/ServiceFormDialog";

export const makeServicesList = ({ unauthErrorHandler, getAllServices, createService, updateService, getAllCategories, goToCategories, getCategoryById }) => () => {
    const [filteredServices, setFilteredServices] = useState([])
    const [selectedService, setSelectedService] = useState(null)
    const [categories, setCategories] = useState([])
    const [query, setQuery] = useState('')
    const [newServiceDialogOpen, setNewServiceDialogOpen] = useState(false)
    const [noCategoriesMessage, setNoCategoriesMessage] = useState(null)

    const loadServices = useCallback(() => getAllServices(query)
    .then(async services => {
        await services.map(async s => {
            if (!s.category_id) { return s }
            const cat = await getCategoryById(s.category_id)
            s.category_name = cat.name
            return s
        })
        return services
    })
    .then(setFilteredServices), [query])
    const loadCategories = useCallback(() =>
        getAllCategories()
            .then(categories => {
                setCategories(categories)
                return categories
            }).then(categories => {
                if (categories?.length === 0) {
                    setNoCategoriesMessage("Crea una categoria para poder crear luego un servicio");
                } else {
                    setNoCategoriesMessage(null);
                }
            }),
        [])
    const load = useCallback(() => {
        Promise.all([
            loadServices(),
            loadCategories()
        ]).catch(e => {
            if (e.status === 401) unauthErrorHandler()
        })
    }, [loadServices, loadCategories])

    return <>
        <ServicesList
            onLoad={load}
            onQueryChange={query => {
                setQuery(query)
                loadServices()
            }}
            noCategoriesErrorMessage={noCategoriesMessage}
            services={filteredServices}
            onServiceSelected={setSelectedService}
            onCreateService={() => {
                if (categories?.length === 0) {
                    goToCategories()
                    return
                }
                setNewServiceDialogOpen(true)
            }}
        />
        <ServiceFormDialog
            open={selectedService != null}
            onSubmit={s => {
                updateService(s)
                    .then(loadServices)
                    .then(() => setSelectedService(null))
            }}
            onCancel={() => setSelectedService(null)}
            service={selectedService}
            categories={categories}
        />

        <ServiceFormDialog
            open={newServiceDialogOpen}
            onSubmit={s => {
                createService(s)
                    .then(loadServices)
                    .then(() => setNewServiceDialogOpen(false))
            }}
            onCancel={() => setNewServiceDialogOpen(false)}
            categories={categories}
        />
    </>
}
