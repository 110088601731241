import { Add } from '@mui/icons-material';
import { Fab, Typography } from '@mui/material';
import React from 'react';


export function DefaultEmptyView({ title, onActionClick }) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign:'center',
            paddingTop: 24,
        }}>
            <Typography variant='h4'>{title}</Typography>
            {!onActionClick ? null : <Fab
                style={{ marginTop: 24 }}
                onClick={e => {
                    e.preventDefault()
                    onActionClick()
                }} variant='extended' color='secondary'>
                <Add />
                Agregar
            </Fab>}
        </div>
    );
}
