import { Button, Icon, Typography } from "@mui/material";
import { calculatePositionTop } from "./CalendarItemUtils";

export default function DetailedScheduleCalendarItem({ onClick, onArrivedClick, onModifyClick, topPositionBias, schedule, calculateLeftPosition, minuteHeight, itemWidth }) {
  function detailedCardLeftPosition() {
    if (window.innerWidth < 600) {
      return 20;
    }
     return calculateLeftPosition() - 32;
  }
  function cardColor() {
    return schedule.status === 'arrived_on_time' ? 'green'
      : schedule.status === 'arrived_late' ? 'red'
        : '#3f51b5';
  }
  return (
    <div key={schedule.id}
      onClick={() => onClick()}
      style={{
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        top: calculatePositionTop(topPositionBias, schedule.starting_date, minuteHeight),
        left: detailedCardLeftPosition(),
        border: '1px solid #ccc',
        position: 'absolute',
        width: itemWidth,
        padding: '10px',
        height: 'auto',
        backgroundColor: cardColor(),
        borderRadius: '8px',
        boxShadow: '4px 4px 10px #0006',
      }}
    >
      <div>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5">{schedule.client.name}</Typography>
          <Icon style={{
            display: schedule.client.other ? 'block' : 'none',
            marginLeft: 8,
            fontSize: 18
          }}>comment</Icon>
        </div>
        <Typography style={{ display: 'block' }}>{schedule.client.other}</Typography>
        {(schedule.client.contact_details && schedule.client.contact_details.length > 0) ? <Typography style={{ display: 'block' }}>Tel: {schedule.client.contact_details[0]?.value}</Typography> : null}

        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h6">Servicios</Typography>
          {schedule.services?.map(service => <Typography key={service.id}>{service.displayable_name}</Typography>)}
        </div>
        <div style={{ display: 'none' }}>
          <Typography>{schedule.services?.map(service => service.displayable_name).join(', ')}</Typography>
        </div>
      </div>
      <div style={{
        marginTop: 8,
        display: 'flex',
        justifyContent: 'end'
      }}>
        <Button
          variant='contained'
          onClick={() => onModifyClick()}
        >Modificar Turno</Button>
        <Button
          style={{ marginLeft: 8 }}
          variant='contained'
          onClick={() => onArrivedClick()}
        >Llegó</Button>
      </div>
    </div>
  );
}
