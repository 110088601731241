import LRUCache from "lru-cache";
import { useCallback, useState } from "react"
import { InMemoryBusinessesRepositoryDecorator } from "../Infrastructure/InMemoryBusinessesRepositoryDecorator"
import { RemoteBusinessesRepository } from "../Infrastructure/RemoteBusinessesRepository"
import BusinessesList from "../Presentation/BusinessesList"
import BusinessForm from "../Presentation/BusinessForm"
import { execute } from "./GetAllBusinesses"
import { FullScreenDialog } from '../../Shared/Components/FullScreenDialog'

const lru = new LRUCache({ maxAge: 1000 * 60 * 60 * 24 })
const remote = new RemoteBusinessesRepository()
const repository = new InMemoryBusinessesRepositoryDecorator(remote, lru)
const getAllBusinesses = execute(repository)

export const makeBusinessesList = () => () => {
    const [businesses, setBusinesses] = useState([])
    const [selectedBusiness, setSelectedBusiness] = useState(null)
    const [newBusinessDialogOpen, setNewBusinessDialogOpen] = useState(false)
    const [isCreatingBusiness, setIsCreatingBusiness] = useState(false)

    const loadBusinesses = useCallback(() => getAllBusinesses().then(setBusinesses), [])
    const load = useCallback(() => loadBusinesses(), [loadBusinesses])

    return <>
        <BusinessesList
            businesses={businesses}
            onLoad={load}
            onCreateBusiness={() => setNewBusinessDialogOpen(true)}
            onSelected={setSelectedBusiness}
        />
        <BusinessFormDialog
            open={selectedBusiness != null}
            onSubmit={b => {
                setIsCreatingBusiness(true)
                repository.update(b)
                    .then(loadBusinesses)
                    .then(() => {
                        setSelectedBusiness(null)
                        setIsCreatingBusiness(false)
                    })
            }}
            onCancel={() => setSelectedBusiness(null)}
            business={selectedBusiness}
            loading={isCreatingBusiness}
        />
        <BusinessFormDialog
            open={newBusinessDialogOpen}
            onSubmit={b => {
                setIsCreatingBusiness(true)
                repository.create(b)
                    .then(loadBusinesses)
                    .then(() => {
                        setNewBusinessDialogOpen(false)
                        setIsCreatingBusiness(false)
                    })
            }}
            onCancel={() => setNewBusinessDialogOpen(false)}
            loading={isCreatingBusiness}
        />
    </>
}

function BusinessFormDialog({ loading, open, onSubmit, onCancel, business }) {
    return <FullScreenDialog open={open}>
        <BusinessForm loading={loading} onSubmit={onSubmit} onCancel={onCancel} business={business} />
    </FullScreenDialog>
}