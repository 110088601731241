
export function calculateHeight(starting_date, ending_date, minuteHeight) {
  const startDate = new Date(starting_date);
  const endDate = new Date(ending_date);
  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes();
  const totalStartMinutes = startHours * 60 + startMinutes;
  const totalEndMinutes = endHours * 60 + endMinutes;
  return ((totalEndMinutes - totalStartMinutes) * minuteHeight) - minuteHeight / 2 - 20;
}

export function calculatePositionTop(topPositionBias, starting_date, minuteHeight) {
  const date = new Date(starting_date)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const totalMinutes = (hours - topPositionBias) * 60 + minutes
  return (totalMinutes * minuteHeight) + 1
}

export function calculateLeftPosition(schedules, schedule, itemWidth) {
  // find schedules that overlap with the current schedule
  const currentScheduleStart = new Date(schedule.starting_date)
  const currentScheduleEnd = new Date(schedule.ending_date)
  const overlappingSchedules = schedules.filter(s => {
    const sStart = new Date(s.starting_date)
    const sEnd = new Date(s.ending_date)
    sEnd.setMinutes(sEnd.getMinutes() + -1)
    return (currentScheduleStart < sEnd && currentScheduleEnd > sStart)
  })
  // find the index of the current schedule in the overlapping schedules
  const index = overlappingSchedules.findIndex(s => s.id === schedule.id)
  // calculate the left position based on the index
  return index * itemWidth + 116
}