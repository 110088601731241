import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { ActionForm } from "../../Shared/Components/ActionForm/ActionForm";
import { ActionFormButtonsContainer } from "../../Shared/Components/ActionForm/ActionFormButtonsContainer";
import { ActionFormContent } from "../../Shared/Components/ActionForm/ActionFormContent";
import { ActionFormTextField } from "../../Shared/Components/ActionForm/ActionFormTextField";

export default function BranchForm({ loading, onCancel, onSubmit, branch }) {

    const [values, setValues] = useState({ name: '' })
    useEffect(() => {
        if (!branch) return
        setValues({ ...branch })
    }, [branch])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit(values)
    }

    const formIsValid = values.name.length >= 3
    const saveButtonTitle = loading ? 'Guardando' : 'Guardar'
    return (
        <ActionForm title={branch ? 'Editar Empresa' : 'Nueva Empresa'} handleSubmit={handleSubmit}>
            <ActionFormContent>
                <ActionFormTextField
                    label='Nombre'
                    placeholder='Nombre de la sucursal (min 3 caracteres)'
                    value={values.name}
                    onChange={handleChange('name')}
                    isRequired
                />
                <ActionFormTextField
                    label='Direccion'
                    placeholder='512th Ocean Drive Av.'
                    value={values.address}
                    onChange={handleChange('address')}
                />
                <ActionFormTextField
                    label='Telefono'
                    placeholder='+1 555 5235'
                    value={values.phone_number}
                    onChange={handleChange('phone_number')}
                />
            </ActionFormContent>
            <ActionFormButtonsContainer>
                <Button disabled={!formIsValid || loading} variant="contained" type='submit' color="primary">{saveButtonTitle}</Button>
                <Button className='form-button' onClick={(e) => {
                    e.preventDefault();
                    onCancel()
                }}>Cancelar</Button>
            </ActionFormButtonsContainer>
        </ActionForm>
    )
}
