import SignupForm from "../Presentation/SignupForm";
import { signupWithCredentials } from '../Domain/signup-service'
import { useLocation } from "wouter";
import { useState } from "react";
import { loginWithCredentials } from "../../Login/Domain/login-service";

export const makeSignupForm = (onLogin) => () => <SignupFormFactory onLogin={onLogin} />

const SignupFormFactory = ({ onLogin }) => {
    const setLocation = useLocation()[1]
    const [error, setError] = useState(null)

    const handleSignupWithCredentials = creds => {
        setError(null)
        signupWithCredentials(creds)
            .then(() => loginWithCredentials(creds))
            .then(() => {
                setLocation('/home')
                onLogin()
            })
            .catch(error => {
                const { message } = error;
                setError(message);
            });
    }
    return <SignupForm
        signupWithCredentials={handleSignupWithCredentials}
        error={error}
        goToLogin={() => setLocation('/login')}
    />
}