import { useLocation } from "wouter";
import { useState } from "react";
import { loginWithCredentials } from "../Domain/login-service";
import LoginForm from "../Presentation/LoginForm"

export const makeLoginForm = (onLogin) => () => <LoginFormFactory onLogin={onLogin} />

const LoginFormFactory = ({ onLogin }) => {
    const setLocation = useLocation()[1]
    const [error, setError] = useState(null)

    const handleLoginWithCredentials = creds => {
        setError(null)
        loginWithCredentials(creds)
            .then(() => {
                setLocation('/home')
                onLogin()
            })
            .catch(error => {
                const { message } = error;
                setError(message);
            });
    }
    return (
        <LoginForm
            loginWithCredentials={handleLoginWithCredentials}
            error={error}
            goToRegister={() => setLocation('/register')}
        />
    )
}
