import { baseURL } from "../../../Shared/Networking/constants";
import { internalServerError, makeRequest } from "../../../Shared/Networking/rest-client";

export async function loginWithCredentials(credentials) {
    const url = `${baseURL}/authentication/login`

    const response = await makeRequest(url, { method: 'POST', body: credentials })
    if (response.status === 401) {
        const { message } = await response.json()
        throw Error(message)
    } else if (response.status !== 200) {
        throw internalServerError()
    }
    const authToken = response.headers.get('auth-token');
    localStorage.setItem('auth-token', authToken)
}