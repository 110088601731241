import LRUCache from "lru-cache";
import { useCallback, useState } from "react"
import { InMemoryBranchesRepositoryDecorator } from "../Infrastructure/InMemoryBranchesRepositoryDecorator"
import { RemoteBranchesRepository } from "../Infrastructure/RemoteBranchesRepository"
import BranchesList from "../Presentation/BranchesList"
import BranchForm from "../Presentation/BranchForm"
import { execute } from "./GetAllBranches"
import { FullScreenDialog } from '../../Shared/Components/FullScreenDialog'

const lru = new LRUCache({ maxAge: 1000 * 60 * 60 * 24 })

export const makeBranchesList = ({ business }) => () => {
    const remote = new RemoteBranchesRepository(business)
    const repository = new InMemoryBranchesRepositoryDecorator(remote, lru)
    const getAllBranches = execute(repository)

    const [branches, setBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [newBranchDialogOpen, setNewBranchDialogOpen] = useState(false)
    const [isCreatingBranch, setIsCreatingBranch] = useState(false)

    const loadBranches = useCallback(() => getAllBranches(business).then(setBranches), [getAllBranches])
    const load = useCallback(() => loadBranches(), [loadBranches])

    return <>
        <BranchesList
            branches={branches}
            onLoad={load}
            onCreateBranch={() => setNewBranchDialogOpen(true)}
            onSelected={setSelectedBranch}
        />
        <BranchFormDialog
            open={selectedBranch != null}
            onSubmit={b => {
                setIsCreatingBranch(true)
                repository.update(b)
                    .then(loadBranches)
                    .then(() => setSelectedBranch(null))
                    .finally(() => setIsCreatingBranch(false))
            }}
            onCancel={() => setSelectedBranch(null)}
            branch={selectedBranch}
            loading={isCreatingBranch}
        />
        <BranchFormDialog
            open={newBranchDialogOpen}
            onSubmit={b => {
                setIsCreatingBranch(true)
                repository.create(b)
                    .then(loadBranches)
                    .then(() => setNewBranchDialogOpen(false))
                    .finally(() => setIsCreatingBranch(false))
            }}
            onCancel={() => setNewBranchDialogOpen(false)}
            loading={isCreatingBranch}
        />
    </>
}

function BranchFormDialog({ loading, open, onSubmit, onCancel, branch }) {
    return <FullScreenDialog open={open}>
        <BranchForm loading={loading} onSubmit={onSubmit} onCancel={onCancel} branch={branch} />
    </FullScreenDialog>
}