import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ActionFormButtonsContainer } from '../../../Shared/Components/ActionForm/ActionFormButtonsContainer';
import { ActionFormTextField } from '../../../Shared/Components/ActionForm/ActionFormTextField';
import List from '../../../Shared/Components/Lists/List';
import ListItem from '../../../Shared/Components/Lists/ListItem';

export default function PriceListDetails({ onLoad, priceListName, prices, onSaveChanges }) {

    const [editing, setEditing] = useState(false)
    useEffect(onLoad, [onLoad])

    const handleSubmit = (changedPrices) => {
        setEditing(false)
        onSaveChanges(changedPrices)
    }

    const form = () => <EditingPricesForm
        onCancel={() => setEditing(false)}
        onSubmit={handleSubmit}
        prices={prices} />
    const viewing = () => <ViewingPricesList prices={prices} />
    return <>
        <div style={{ padding: 16, maxWidth: 650, margin: 'auto' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 20, justifyContent: 'space-between' }}>
                <Typography variant='h5'>{`Editar Lista de precios ${priceListName ?? ''}`}</Typography>
                {editing ? null : <Button variant="contained" onClick={() => setEditing(true)} color="primary">Editar</Button>}
            </div>
            {editing ? form() : viewing()}
        </div>
    </>
}

const ViewingPricesList = ({ prices }) => <>
    <List subheaders={['Servicio', 'Precio']} >
        {prices.map((price, index) => <ListItem key={index} disableGutters columns={[
            price.service.displayable_name,
            <Typography>{price.price?.amount ? `$${price.price?.amount}` : ''}</Typography>
        ]} />)}
    </List>
</>

const EditingPricesForm = ({ onSubmit, onCancel, prices }) => {
    const [changedPrices, setChangedPrices] = useState([])

    useEffect(() => { setChangedPrices([...prices]) }, [prices])

    const DifferenceFromOriginal = ({ index }) => {
        const originalAmount = prices[index].price?.amount;
        const isDifferent = originalAmount !== changedPrices[index].price?.amount
        return isDifferent ? <Typography>{originalAmount ?? 'Precio Nuevo'}</Typography> : null
    }

    const handleChange = (index) => (e) => {
        const newChangedPrices = [...changedPrices]
        const changedPrice = { ...newChangedPrices[index] }
        const newAmount = e.target.value
        changedPrice.price = newAmount === '' ? null : {
            amount: e.target.value,
            service_id: changedPrice.service.id
        }
        newChangedPrices[index] = changedPrice
        setChangedPrices(newChangedPrices)
    }

    const cancel = () => {
        setChangedPrices(prices)
        onCancel()
    }

    const canSave = prices.every((p, i) => p.price?.amount === changedPrices[i]?.price?.amount)

    return <>
        <List subheaders={['Servicio', 'Precio', 'Precio Original']} >

            {changedPrices.map((price, index) => <ListItem key={index} disableGutters
                columns={[
                    price.service.displayable_name,
                    < ActionFormTextField
                    style={{ width: 85 }}
                        value={price.price?.amount ?? ''}
                        onChange={handleChange(index)}
                    />,
                    <DifferenceFromOriginal index={index} />
                ]} />
            )}
        </List>

        <ActionFormButtonsContainer>
            <Button
                disabled={canSave}
                variant="contained"
                color="primary"
                onClick={() => onSubmit(changedPrices)}
            >
                Guardar
            </Button>
            <Button
                className='form-button'
                onClick={(e) => {
                    e.preventDefault();
                    cancel()
                }}
            >
                Cancelar
            </Button>
        </ActionFormButtonsContainer>
    </>
}
