import { makeServiceCategoriesList } from "../../ServiceCategories/DI/factory";
import { execute as GetAllServiceCategories } from "../../ServiceCategories/DI/GetAllServiceCategories";

export class ServiceCategoriesFlowFactory {
  constructor({ serviceCategorieRepository, unauthErrorHandler }) {
    this.serviceCategorieRepository = serviceCategorieRepository;
    this.unauthErrorHandler = unauthErrorHandler;
  }

  make() {
    return makeServiceCategoriesList({
      unauthErrorHandler: this.unauthErrorHandler,
      create: (c) => this.serviceCategorieRepository.create(c),
      update: c => this.serviceCategorieRepository.update(c),
      getAll: GetAllServiceCategories(this.serviceCategorieRepository)
    });
  }
}
