import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class RemotePriceListsRepository {
    constructor(selectedBusiness) {
        this.selectedBusiness = selectedBusiness
    }

    async getAll() {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/price-lists`
        return await jsonRequest(`${url}`).then(response => response.data)
    }

    async create(pricelist) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/price-lists`
        return await jsonRequest(`${url}`, { method: 'POST', body: pricelist })
    }

    async update(pricelist) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/price-lists`
        return await jsonRequest(`${url}`, { method: 'PUT', body: pricelist })
    }
}
