import { TextField, MenuItem } from "@mui/material";

export function ActionFormOptionsField({ options = [], label, defaultValue, value, onChange, variant = 'outlined', isRequired = false }) {
    if (!value || value === '') {
        value = defaultValue;
    }
    return (
        <TextField select
            className='text-field'
            variant={variant}
            label={label}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            required={isRequired}
        >
            {options.map(i => <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>
            )}
        </TextField>
    );
}
