export const execute = (repository) => (query) => {
    return repository.getAll()
        .then(filter(query))
        .then(sort)
}

const sort = (employees) => {
    return employees.sort((e1, e2) => e1.name.toLowerCase() > e2.name.toLowerCase() ? 1 : -1);
};

const filter = (search) => (employees) => {
    if (search === '') {
        return employees;
    }
    return employees.filter(s => s.name?.toLowerCase().includes(search.toLowerCase()));
};
