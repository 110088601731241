import { useCallback, useEffect, useState } from "react"
import { NewScheduleForm } from "./NewScheduleForm"
import { getAllServices } from "../../Services/DI/GetAllServices"
import { Typography } from "@mui/material"

const NewScheduleFormFlow = ({ createClientWithDocNumber, preselectedClient, scheduleToEdit, goToHome, schedulesRepository, configurationProvider, clientsRepository, servicesRepository }) => {
    const [schedules, setSchedules] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [filteredClients, setFilteredClients] = useState([])
    const branchConfiguration = configurationProvider.getConfiguration()

    const onDateSelected = useCallback(async (date) => {
        const schedules = await schedulesRepository.getSchedulesForDate(date)
        setSchedules(schedules)
        return schedules
    }, [schedulesRepository])

    async function searchClients(searchTerm) {
        const clients = await clientsRepository.getAllClients(searchTerm)
        setFilteredClients(clients)
    }

    const searchServices = useCallback(async (searchTerm) => {
        const services = await getAllServices(servicesRepository)(searchTerm)
        setFilteredServices(services)
        return services
    }, [servicesRepository])

    async function createSchedule(schedule) {
        if (scheduleToEdit) {
            await schedulesRepository.updateSchedule(schedule)
            showAlert('Turno actualizado con éxito')
            goToHome()
            return
        }
        await schedulesRepository.createSchedule(schedule)
        showAlert('Turno creado con éxito')
        goToHome()
    }

    function showAlert(message) {
        alert(message)
    }

    const load = useCallback(async () => {
        await searchServices('')
        await onDateSelected(new Date())
    }, [onDateSelected, searchServices])

    useEffect(() => {
        load()
    }, [load])

    return <>
        <NewScheduleForm
            createClientWithDocNumber={createClientWithDocNumber}
            preselectedClient={preselectedClient}
            scheduleToEdit={scheduleToEdit}
            schedules={schedules}
            branchConfiguration={branchConfiguration}
            onDateSelected={onDateSelected}
            filteredClients={filteredClients}
            searchClients={searchClients}
            filteredServices={filteredServices}
            searchServices={searchServices}
            createSchedule={createSchedule}
        />
    </>
}

export const makeNewScheduleFormFlow = ({
    preselectedClientId = null,
    scheduleToEdit = null,
    servicesRepository,
    clientsRepository,
    schedulesRepository,
    branchConfigurationRepository,
    createClientWithDocNumber,
    goToHome
}) => () => {
    const [preselectedClient, setPreselctedClient] = useState(null)

    useEffect(() => {
        if (preselectedClientId) {
            clientsRepository.getClientById(preselectedClientId)
                .then(setPreselctedClient)
        }
    }, [])

    if (preselectedClientId && !preselectedClient) {
        return <Typography>Cargando</Typography>
    }
    return <NewScheduleFormFlow
        createClientWithDocNumber={createClientWithDocNumber}
        scheduleToEdit={scheduleToEdit}
        preselectedClient={preselectedClient}
        goToHome={goToHome}
        schedulesRepository={schedulesRepository}
        configurationProvider={branchConfigurationRepository}
        clientsRepository={clientsRepository}
        servicesRepository={servicesRepository}
    />
}

export const makeEditScheduleFormFlow = ({ createClientWithDocNumber, scheduleIdToEdit, servicesRepository, clientsRepository, schedulesRepository, branchConfigurationRepository, goToHome }) => () => {
    const [scheduleToEdit, setScheduleToEdit] = useState(null)

    useEffect(() => {
        schedulesRepository.getScheduleById(scheduleIdToEdit)
            .then(setScheduleToEdit)
    }, [])

    if (!scheduleToEdit) {
        return <Typography>Cargando</Typography>
    }

    return <NewScheduleFormFlow
        createClientWithDocNumber={createClientWithDocNumber}
        scheduleToEdit={scheduleToEdit}
        goToHome={goToHome}
        schedulesRepository={schedulesRepository}
        configurationProvider={branchConfigurationRepository}
        clientsRepository={clientsRepository}
        servicesRepository={servicesRepository}
    />
}
