import React from 'react';
import { FullScreenDialog } from '../../../Shared/Components/FullScreenDialog';
import EmployeeForm from './EmployeeForm';

export function EmployeeFormDialog({ open, onSubmit, onCancel, employee }) {
    return <FullScreenDialog open={open}>
        <EmployeeForm onSubmit={onSubmit} onCancel={onCancel} employee={employee} />
    </FullScreenDialog>
}

