import React, { useEffect } from 'react'
import ListItem from '../../Shared/Components/Lists/ListItem'
import NonQueriableList from '../../Shared/Components/Lists/NonQueriable/NonQueriableList'
import PageContainer from '../../Shared/Components/PageContainer/PageContainer'
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView'

export default function BusinessesList({ onSelected, businesses, onLoad, onCreateBusiness }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={onCreateBusiness} />
    return (
        <PageContainer>
            <NonQueriableList
                emptyListView={emptyView('Crea tu primer negocio')}
                onAdd={onCreateBusiness}
                subheaders={['Nombre']}
            >
                {businesses.map((business) => (
                    <ListItem
                        key={business.id}
                        columns={[business.name]}
                        onItemSelected={() => onSelected(business)}
                    />
                ))}
            </NonQueriableList>
        </PageContainer>
    )
}
