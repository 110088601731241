import { Divider, ListItem as MatListItem, ListItemText as MatListItemText } from '@mui/material'
import React from 'react'

export default function ListItem({ columns, onItemSelected, disableGutters }) {
    function className(index) {
        const classes = ['list-item-content']
        if (index > 2)
            classes.push('non-essential')
        return classes.join(' ')
    }
    const width = 100 / columns.length
    return (
        <>
            <MatListItem button onClick={onItemSelected} disableGutters={disableGutters} >
                {columns.map((col, index) =>
                    <MatListItemText key={index} style={{ width: `${width}%` }} className={className(index)} primary={col} />
                )}
            </MatListItem>
            <Divider />
        </>
    )
}
