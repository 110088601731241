import { makeServicesList } from "../../Services/DI/factory";
import { getAllServices } from "../../Services/DI/GetAllServices";
import { execute as GetAllServiceCategories } from "../../ServiceCategories/DI/GetAllServiceCategories";

export class ServicesFlowFactory {
  constructor({ serviecsRpository, serviceCategorieRepository, unauthErrorHandler, setLocation }) {
    this.unauthErrorHandler = unauthErrorHandler
    this.setLocation = setLocation
    this.serviecsRpository = serviecsRpository
    this.serviceCategorieRepository = serviceCategorieRepository
  }

  makeServicesList() {
    return makeServicesList({
      unauthErrorHandler: this.unauthErrorHandler,
      getAllServices: getAllServices(this.serviecsRpository),
      createService: s => this.serviecsRpository.create(s),
      updateService: s => this.serviecsRpository.update(s),
      getAllCategories: GetAllServiceCategories(this.serviceCategorieRepository),
      goToCategories: () => this.setLocation('/service-categories'),
      getCategoryById: id => this.serviceCategorieRepository.getById(id)
    });
  }
}
