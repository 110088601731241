import { Button, Icon } from "@mui/material";
import { ActionFormDateField } from "../../Shared/Components/ActionForm/ActionFormDateField";

export function ScheduleCalendarDatePicker({ selected, onDateSelected }) {
  
  return <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '1rem',
    }}
    >
      <Button style={{
        display: (selected.getDate() === new Date().getDate()) ? 'none' : 'block'
      }}
        variant="contained"
        onClick={() => onDateSelected(new Date())}
      >
        Hoy
      </Button>
      <div style={{
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '1rem',
      }}
      >
        <Button onClick={() => {
          const newDate = new Date(selected)
          newDate.setDate(newDate.getDate() - 1)
          onDateSelected(newDate)
        }}>
          <Icon>chevron_left</Icon>
        </Button>
        <ActionFormDateField
          openTo="day"
          value={selected}
          onChange={onDateSelected}
          variant='outlined'
        />
        <Button onClick={() => {
          const newDate = new Date(selected)
          newDate.setDate(newDate.getDate() + 1)
          onDateSelected(newDate)
        }}>
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </div>
}
