export async function makeRequest(url, { method = 'GET', body = null } = {}) {

    if (body)
        body = JSON.stringify(body)

    const newHeaders = {
        'content-type': 'application/json'
    }
    const token = localStorage.getItem('auth-token')
    if (token) {
        newHeaders['auth-token'] = token
    }
    const response = await fetch(url, {
        method: method,
        headers: newHeaders,
        body: body
    })
    if (!response.ok) {
        throw response
    }
    return response
}

export async function jsonRequest(url, { method = 'GET', body = null } = {}) {
    const response = await makeRequest(url, { method, body })
    const json = await response.json()
    return json
}

export function internalServerError(customMessage) {
    return Error(customMessage || 'Internal Server Error')
}