export const getAllServices = (repository) => (query) => {
    return repository.getAll()
        .then(filter(query))
        .then(sort)
}

const sort = (services) => {
    return services.sort((s1, s2) => s1.name.toLowerCase() > s2.name.toLowerCase() ? 1 : -1);
};

const filter = (search) => (services) => {
    if (search === '') {
        return services;
    }
    search = search.toLowerCase();
    return services.filter(s => s.name?.toLowerCase().includes(search)
        || s.displayable_name?.toLowerCase().includes(search)
    );
};
