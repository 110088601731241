import { Button, IconButton } from "@mui/material"
import { Add, Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ActionForm } from "../../../Shared/Components/ActionForm/ActionForm";
import { ActionFormContent } from "../../../Shared/Components/ActionForm/ActionFormContent";
import { ActionFormButtonsContainer } from "../../../Shared/Components/ActionForm/ActionFormButtonsContainer";
import { ActionFormSection } from "../../../Shared/Components/ActionForm/ActionFormSection";
import { ActionFormDateField } from "../../../Shared/Components/ActionForm/ActionFormDateField";
import { ActionFormTextField } from "../../../Shared/Components/ActionForm/ActionFormTextField";
import { ActionFormOptionsField } from "../../../Shared/Components/ActionForm/ActionFormOptionsField";

export default function NewClientForm({ preloadDocumentNumber, loading, onCancel, onSubmit, client, scheduleAppointment }) {

    const identificationTypes = [
        { value: 'dni', name: 'DNI' },
    ]

    const contactTypes = [
        { value: 'phone', name: 'Tel. Fijo' },
        { value: 'cellphone', name: 'Celular' },
    ]

    const [values, setValues] = useState({
        name: '',
        last_name: '',
        email: "",
        other: "",
        birthday: new Date(),
        identification_type: identificationTypes[0].value,
        identification_number: preloadDocumentNumber ?? ""
    });

    const [contact_details, setContactDetails] = useState([{
        type: 'cellphone',
        value: ''
    }])

    useEffect(() => {
        if (!client) {
            return
        }
        setValues({
            ...client,
            identification_number: client.identification?.number,
            identification_type: client.identification?.type
        })
        setContactDetails(client.contact_details)
    }, [client])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleContactDetailNumberChange = (index) => (event) => {
        const cds = [...contact_details]
        const cd = cds[index]
        cd.value = event.target.value
        cds[index] = cd
        setContactDetails(cds)
    }

    const handleContactDetailTypeChange = (index) => (event) => {
        const cds = [...contact_details]
        const cd = cds[index]
        cd.type = event.target.value
        cds[index] = cd
        setContactDetails(cds)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const newValues = {
            id: values.id,
            name: values.name,
            last_name: values.last_name,
            email: values.email,
            other: values.other,
            birthday: values.birthday,
            status: values.status,
            identification: {
                number: values.identification_number,
                type: values.identification_type
            },
            contact_details: contact_details,
        }
        onSubmit(newValues)
    }

    const handleBirthday = (newDate) => {
        setValues({ ...values, birthday: newDate });
    }

    return (
        <ActionForm title={client ? "Editar Cliente" : 'Nuevo Cliente'} handleSubmit={handleSubmit}>
            <ActionFormContent>
                <ActionFormTextField
                    label='Nombre'
                    value={values.name}
                    onChange={handleChange('name')}
                    isRequired
                />
                <ActionFormTextField
                    label='Apellido'
                    value={values.last_name}
                    onChange={handleChange('last_name')}
                    isRequired
                />
                <ActionFormSection title='Documento'>
                    <ActionFormContent direction='horizontal'>
                        <ActionFormOptionsField
                            label="Tipo"
                            options={identificationTypes}
                            defaultValue='dni'
                            value={values.identification_type}
                            onChange={handleChange('identification_type')}
                            isRequired
                        />
                        <ActionFormTextField
                            label='Tipo Documento'
                            value={values.identification_number}
                            onChange={handleChange('identification_number')}
                            isRequired
                        />
                    </ActionFormContent>
                </ActionFormSection>
                <ActionFormDateField
                    label='Fecha de nacimiento'
                    value={values.birthday}
                    onChange={handleBirthday}
                />
                <ActionFormSection title='Contacto'>
                    <ActionFormContent>
                        {contact_details.map((cd, index) => {
                            return (
                                <ActionFormContent direction='horizontal' key={index}>

                                    <ActionFormOptionsField
                                        label="Contacto"
                                        options={contactTypes}
                                        defaultValue='cellphone'
                                        value={cd.type}
                                        isRequired
                                        onChange={handleContactDetailTypeChange(index)}
                                    />
                                    <ActionFormTextField
                                        label='Valor'
                                        isRequired
                                        value={cd.value}
                                        onChange={handleContactDetailNumberChange(index)}
                                    />
                                    <IconButton onClick={e => {
                                        e.preventDefault()
                                        const cds = [...contact_details]
                                        cds.splice(index, 1)
                                        setContactDetails(cds)
                                    }
                                    } >
                                        <Delete />
                                    </IconButton>
                                </ActionFormContent>
                            )
                        })}
                        <Button variant="outlined" startIcon={<Add />} className='text-field' onClick={(e) => {
                            e.preventDefault()
                            setContactDetails([...contact_details, { type: 'cellphone', value: '' }])
                        }} >Agregar Contacto</Button>

                        <ActionFormTextField
                            label='Email'
                            value={values.email}
                            onChange={handleChange('email')}
                            isRequired={false}
                        />
                    </ActionFormContent>
                </ActionFormSection>
                <ActionFormSection title='Otros'>
                    <ActionFormContent>
                        <ActionFormTextField
                            label='Otros'
                            value={values.other}
                            onChange={handleChange('other')}
                            isRequired={false}
                            isMultiline
                        />
                    </ActionFormContent>
                </ActionFormSection>
            </ActionFormContent>
            <Buttons
                loading={loading}
                cancel={() => onCancel()}
                clientSelected={client}
                scheduleAppointment={scheduleAppointment}
            />
        </ActionForm>

    )

    function Buttons({ loading, cancel, clientSelected, scheduleAppointment }) {
        const saveButtonTitle = loading ? 'Guardando' : 'Guardar'
        return (
            <ActionFormButtonsContainer>
                <Button disabled={loading} variant="contained" type='submit' color="primary">{saveButtonTitle}</Button>
                {onCancel ? <Button className='form-button' onClick={(e) => {
                    e.preventDefault();
                    cancel()
                }}>Cancelar</Button> : null}
                {clientSelected ? <Button
                    variant="outlined"
                    className='form-button' onClick={(e) => {
                        e.preventDefault();
                        scheduleAppointment(clientSelected)
                    }}>Agendar Turno</Button> : null}
            </ActionFormButtonsContainer>
        )
    }
}
