import { useEffect } from 'react'
import QueryableList from '../../Shared/Components/Lists/Queryable/QueryableList';
import PageContainer from '../../Shared/Components/PageContainer/PageContainer';
import ListItem from '../../Shared/Components/Lists/ListItem';
import { DefaultEmptyView } from '../../Shared/Components/DefaultEmptyView';

export default function EmployeesList({ onQueryChange, employees, onLoad, onEmployeeSelected, onCreateEmployee }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={onCreateEmployee} />

    return (
        <PageContainer>
            <QueryableList
                onQueryChange={onQueryChange}
                onAdd={onCreateEmployee}
                subheaders={['Nombre', 'Detalle']}
                emptyListView={emptyView('Crear primer empleado')}
                emptyQueriedListView={emptyView('El empleado no existe')}
            >
                {employees.map((employee) => (
                    <ListItem
                        key={employee.id}
                        columns={[
                            employee.name,
                            employee.other
                        ]}
                        onItemSelected={() => onEmployeeSelected(employee)}
                    />
                ))}
            </QueryableList>
        </PageContainer>
    )
}
