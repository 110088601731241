import { InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react'
import './SearchField.scss'

export default function SearchField({ onChange, placeholder }) {

    const [value, setValue] = useState('');

    function setValueToField(theValue) {
        setValue(theValue)
        onChange(theValue)
    }

    return (
        <div className='search-field-container' component="form">
            <SearchIcon className='iconButton' />
            <InputBase
                onChange={e => setValueToField(e.target.value)}
                className='input'
                value={value}
                placeholder={placeholder}
            />
        </div>
    )
}
