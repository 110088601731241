import { List as MatList } from "@mui/material";
import './List.scss'
import ListSubheader from "./ListSubheader";

export default function List({ subheaders, component = 'nav', children}) {
    const subheader = subheaders && subheaders.length > 0 ? <ListSubheader columns={subheaders} /> : null
    return (
        <MatList component={component} className="list" >
            {subheader}
            {children}
        </MatList>
    )
}
