import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function BranchSelection({ onLoad, businesses, onBranchSelected }) {
    useEffect(onLoad, [onLoad])
    const [opened, setOpened] = useState({})

    const openCloseBusiness = (businessId, open) => {
        const o = { ...opened }
        o[businessId] = open
        setOpened(o)
    }

    const isBusinessOpen = (businessId) => opened[businessId] ?? false

    return (
        <List>
            {businesses.map(bu => (
                <div key={bu.id}>
                    <ListItemButton onClick={() => openCloseBusiness(bu.id, !isBusinessOpen(bu.id))}>
                        {isBusinessOpen(bu.id) ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary={bu.name} />
                    </ListItemButton>
                    <Collapse  component="li" in={isBusinessOpen(bu.id)} >
                        <List>
                            {bu.branches.map(br => (
                                <ListItemButton key={br.id} onClick={() => onBranchSelected(bu, br)}>
                                    <Typography>{br.name}</Typography> <Typography>{br.address}</Typography>
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                </div>
            ))}
        </List>
    )
}
