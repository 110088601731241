import { makePriceListsList } from "../../ServicePriceLists/DI/factory";
import { execute as GetAllPriceLists } from "../../ServicePriceLists/DI/GetAllPriceLists";

export class PriceListFlowFactory {
  constructor({ unauthErrorHandler, priceListsRepository, setLocation }) {
    this.unauthErrorHandler = unauthErrorHandler;
    this.priceListsRepository = priceListsRepository;
    this.setLocation = setLocation;
  }

  make() {
    return makePriceListsList({
      unauthErrorHandler: this.unauthErrorHandler,
      getAllPriceLists: GetAllPriceLists(this.priceListsRepository),
      repository: this.priceListsRepository,
      onPriceListSelected: ({ id }) => this.setLocation(`/services/price-lists/${id}`)
    });
  }
}
