import { baseURL } from "../../Shared/Networking/constants"
import { jsonRequest } from "../../Shared/Networking/rest-client"

export const getAllPrices = async (businessId, priceListId) => {
    const url = `${baseURL}/businesses/${businessId}/price-lists/${priceListId}/prices`
    return await jsonRequest(`${url}`).then(response => response.data)
}

export const bulkCreatePrices = async (businessId, priceListId, prices) => {
    const body = prices.filter(p => p.price).map(p => {
        const { service_id, amount } = p.price
        return { service_id, amount }
    })
    return await jsonRequest(
        `${baseURL}/businesses/${businessId}/price-lists/${priceListId}/prices/bulk`,
        { method: 'POST', body }
    )
}