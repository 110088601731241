import { TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";


export function ActionFormTimeField({ label, value, onChange, variant = 'outlined', isRequired = false }) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
            className='text-field'
            label={label}
            value={value}
            ampm={false}
            onChange={onChange}
            inputFormat="HH:mm"
            mask="__:__"
            closeOnSelect={false}
            views={['hours', 'minutes']}
            renderInput={props => <TextField
                {...props}
                variant={variant}
                required={isRequired} />} />
    </LocalizationProvider>;
}
