import React from 'react';
import { FullScreenDialog } from '../../../Shared/Components/FullScreenDialog';
import ServiceForm from './ServiceForm';

export function ServiceFormDialog({ open, onSubmit, onCancel, service, categories }) {
    return <FullScreenDialog open={open}>
        <ServiceForm onSubmit={onSubmit} onCancel={onCancel} service={service} categories={categories} />
    </FullScreenDialog>
}

