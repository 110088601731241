import ClientsList from "../Presentation/ClientsList"
import { useCallback, useState } from "react";
import { ClientFormDialog } from "../Presentation/NewClientForm/ClientFormDialog";
import NewClientForm from "../Presentation/NewClientForm/NewClientForm";

export const makeClientsList = ({ repository, makeScheduleForClient }) => () => {
    const [clients, setClients] = useState([])
    const [error, setError] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)
    const [newClientDialogOpen, setNewClientDialogOpen] = useState(false)
    const [isCreatingClient, setIsCreatingClient] = useState(false)
    const loadClients = useCallback((query) => {
        repository.getAllClients(query)
            .then(srces => {
                srces.sort((s1, s2) => s1.last_name.toLowerCase() > s2.last_name.toLowerCase() ? 1 : -1)
                return srces
            })
            .then(setClients)
            .catch(setError)
    }, [])

    const load = useCallback(() => { loadClients('') }, [loadClients])

    return <>
        <ClientsList
            onLoad={load}
            onQueryChange={loadClients}
            clients={clients}
            error={error}
            onClientSelected={setSelectedClient}
            makeScheduleForClient={makeScheduleForClient}
            createClient={() => setNewClientDialogOpen(true)}
        />
        <ClientFormDialog
            open={selectedClient != null}
            onSubmit={v => {
                setIsCreatingClient(true)
                repository.editClient(v)
                    .then(() => loadClients(''))
                    .then(() => setSelectedClient(null))
                    .catch(setError)
                    .finally(() => setIsCreatingClient(false))
            }}
            onCancel={() => setSelectedClient(null)}
            client={selectedClient}
            makeScheduleForClient={makeScheduleForClient}
            loading={isCreatingClient}
        />
        <ClientFormDialog
            open={newClientDialogOpen}
            onSubmit={value => {
                setIsCreatingClient(true)
                repository.createClient(value)
                    .then(() => loadClients(''))
                    .then(() => setNewClientDialogOpen(false))
                    .catch(setError)
                    .finally(() => setIsCreatingClient(false))
            }}
            loading={isCreatingClient}
            onCancel={() => setNewClientDialogOpen(false)}
        />
    </>
}

export const makeNewClientFormFlow = ({ documentNumber, repository, onClientCreated }) => () => {
    const [isCreatingClient, setIsCreatingClient] = useState(false)

    return <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        padding: '1rem',
        maxWidth: '500px'
    }}>
        <NewClientForm
            loading={isCreatingClient}
            preloadDocumentNumber={documentNumber}
            onSubmit={value => {
                setIsCreatingClient(true)
                repository.createClient(value)
                    .then(() => onClientCreated())
            }}
        />
    </div>
}