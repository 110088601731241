import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import SearchField from '../SearchField';

export default function DebouncedSearchField({ onChange, placeholder }) {
    const inputStream = new Subject().pipe(
        debounceTime(250),
        distinctUntilChanged(),
    )

    inputStream.subscribe(onChange)

    return <SearchField onChange={v => inputStream.next(v)} placeholder={placeholder} />
}
