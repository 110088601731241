import { Button, Typography } from "@mui/material";
import SchedulesCalendar from "../../SchedulesHomeScreen/presentation/SchedulesCalendar";
import { SimpleScheduleCalendarItem } from "../../SchedulesHomeScreen/presentation/SimpleScheduleCalendarItem";
import { ActionFormTimeField } from "../../Shared/Components/ActionForm/ActionFormTimeField";
import { calculateLeftPosition } from "../../SchedulesHomeScreen/presentation/CalendarItemUtils";
import { NewScheduleCalendarItem } from "../../SchedulesHomeScreen/presentation/NewScheduleCalendarItem";

export function DateTimeSelectionStep({ configuration, schedules, selectedDate, onCalendarDateSelected, onTimeHourSelected, scheduleToBeCreated, onDateConfirmed }) {
    const newSchedule = scheduleToBeCreated();
    const newScheduleColor = '#008866';
    const editScheduleColor = '#aa3300';
    const existentScheduleColor = '#3f51b5';

    return <>
        <div style={{
            padding: '1rem',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
        }}>
            <ColorLegend color={existentScheduleColor} legend='Turnos existentes' />
            <ColorLegend color={newScheduleColor} legend='Turno a crear' />
            <ColorLegend color={editScheduleColor} legend='Turno siendo editado' />
        </div>
        <SchedulesCalendar
            size={{ height: 500 }}
            headerContent={<div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <ActionFormTimeField
                    value={selectedDate}
                    onChange={onTimeHourSelected} />
                <Button
                    variant='contained'
                    color='primary'
                    onClick={onDateConfirmed}
                >Continuar</Button>
            </div>}
            configuration={configuration}
            selectedDate={selectedDate}
            onDateSelected={onCalendarDateSelected}
        > {[...schedules, newSchedule]?.map(schedule => (
            schedule.id === newSchedule.id ?
                <NewScheduleCalendarItem
                    style={{ maxWidth: '30%' }}
                    key={schedule === newSchedule ? schedule.id : 'new'}
                    openingHour={configuration.openingTime.hours}
                    schedule={schedule}
                    backgroundColor={schedule === newSchedule ? newScheduleColor : editScheduleColor}
                    calculateLeftPosition={() => calculateLeftPosition([scheduleToBeCreated(), ...schedules], schedule, 30)}
                    minuteHeight={configuration.minuteHeight}
                    itemWidth={90} /> :
                <SimpleScheduleCalendarItem
                    key={schedule.id}
                    openingHour={configuration.openingTime.hours}
                    schedule={schedule}
                    backgroundColor={existentScheduleColor}
                    calculateLeftPosition={() => calculateLeftPosition([scheduleToBeCreated(), ...schedules], schedule, 30)}
                    minuteHeight={configuration.minuteHeight}
                    itemWidth={90} />
        ))}  </SchedulesCalendar></>
}

function ColorLegend({ color, legend }) {
    return <div style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
    }}>
        <div style={{
            width: 20,
            height: 10,
            borderRadius: 5,
            backgroundColor: color
        }} />
        <Typography variant='caption'>{legend}</Typography>
    </div>
}