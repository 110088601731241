import './forms.scss'

export function ActionForm({ handleSubmit, title, children }) {
    return (
        <div className='form-container'>
            <h3>{title}</h3>
            <form className='form' onSubmit={handleSubmit}>
                {children}
            </form>
        </div>
    );
}
