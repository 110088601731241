import { useEffect } from 'react'
import NonQueryableList from '../../../Shared/Components/Lists/NonQueriable/NonQueriableList';
import PageContainer from '../../../Shared/Components/PageContainer/PageContainer';
import ListItem from '../../../Shared/Components/Lists/ListItem';
import { DefaultEmptyView } from '../../../Shared/Components/DefaultEmptyView';

export default function PriceListsList({ pricelists, onLoad, onPriceListSelected, onCreatePriceList }) {
    useEffect(onLoad, [onLoad])
    const emptyView = (title) =>
        <DefaultEmptyView
            title={title}
            onActionClick={onCreatePriceList} />

    return (
        <PageContainer>
            <NonQueryableList
                onAdd={onCreatePriceList}
                emptyListView={emptyView('Crear primer lista de precios')}
            >
                {pricelists.map((pricelist) => (
                    <ListItem
                        key={pricelist.id}
                        columns={[pricelist.name]}
                        onItemSelected={() => onPriceListSelected(pricelist)}
                    />
                ))}
            </NonQueryableList>
        </PageContainer>
    )
}
