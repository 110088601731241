import { Typography } from "@mui/material";
import { calculateHeight, calculatePositionTop } from "./CalendarItemUtils";

export function SimpleScheduleCalendarItem({ backgroundColor, schedule, calculateLeftPosition, itemWidth, minuteHeight, openingHour }) {
  return (
    <Typography
      style={{
        cursor: 'pointer',
        top: calculatePositionTop(openingHour, schedule.starting_date, minuteHeight),
        left: calculateLeftPosition(),
        border: '1px solid #ccc',
        position: 'absolute',
        width: itemWidth,
        padding: '10px',
        height: calculateHeight(schedule.starting_date, schedule.ending_date, minuteHeight),
        backgroundColor,
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.7)',
      }}
      variant="caption"
    >
      {schedule.services?.map(service => service.displayable_name).join(', ')}</Typography>
  );
}
