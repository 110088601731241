import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class ApiSchedulesRepository {
    constructor(businessId, branchId) {
        this.businessId = businessId;
        this.branchId = branchId;
    }

    async getScheduleById(scheduleId) {
        const url = `${baseURL}/businesses/${this.businessId}/branches/${this.branchId}/schedules/${scheduleId}`;
        const data = await jsonRequest(url);
        return data;
    }

    async getSchedulesForDate(date) {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let formattedDate = year + '-' + month + '-' + day;

        const url = `${baseURL}/businesses/${this.businessId}/branches/${this.branchId}/schedules/date/${formattedDate}`;
        var data = await jsonRequest(url);
        data = data.map(s => {
            console.log(s.starting_date)
            // s.starting_date = new Date(s.starting_date);
            // console.log(s.starting_date)
            return s;
        });
        return data;
    }

    async markClientArrived(scheduleId) {
        const url = `${baseURL}/businesses/${this.businessId}/branches/${this.branchId}/schedules/${scheduleId}/arrived`;
        return await jsonRequest(url, { method: 'PUT' });
    }

    async updateSchedule(schedule) {
        const url = `${baseURL}/businesses/${this.businessId}/branches/${this.branchId}/schedules`;
        const body = {
            id: schedule.id,
            services: schedule.services.map(s => s.id),
            client_id: schedule.client.id,
            starting_date: schedule.starting_date.toISOString(),
        }
        return await jsonRequest(url, { method: 'PUT', body });
    }

    async createSchedule(schedule) {
        const url = `${baseURL}/businesses/${this.businessId}/branches/${this.branchId}/schedules`;
        const body = {
            services: schedule.services.map(s => s.id),
            client_id: schedule.client.id,
            starting_date: schedule.starting_date.toISOString(),
        }
        return await jsonRequest(url, { method: 'POST', body });
    }
}
