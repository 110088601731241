import { useCallback, useState } from "react";
import ServiceCategoriesList from "../Presentation/ServiceCategoriesList";
import { ServiceCategoryFormDialog } from "../Presentation/Form/ServiceCategoryFormDialog";


export const makeServiceCategoriesList = ({ unauthErrorHandler, create, update, getAll }) => () => {
    const [selectedServiceCategory, setSelectedServiceCategory] = useState(null)
    const [newServiceCategoryDialogOpen, setNewServiceCategoryDialogOpen] = useState(false)
    const [categories, setCategories] = useState([])

    const loadCategories = useCallback(() => getAll().then(setCategories).catch(e => {
        if (e.status === 401)
            unauthErrorHandler();
    }), [])
    const load = useCallback(loadCategories, [loadCategories])

    return <>
        <ServiceCategoriesList
            onLoad={load}
            categories={categories}
            onCategorySelected={setSelectedServiceCategory}
            onCreateCategory={() => setNewServiceCategoryDialogOpen(true)}
        />
        <ServiceCategoryFormDialog
            open={selectedServiceCategory != null}
            onSubmit={s => {
                update(s)
                    .then(loadCategories)
                    .then(() => setSelectedServiceCategory(null))
            }}
            onCancel={() => setSelectedServiceCategory(null)}
            category={selectedServiceCategory}
        />

        <ServiceCategoryFormDialog
            open={newServiceCategoryDialogOpen}
            onSubmit={s => {
                create(s)
                    .then(loadCategories)
                    .then(() => setNewServiceCategoryDialogOpen(false))
            }}
            onCancel={() => setNewServiceCategoryDialogOpen(false)}
        />
    </>
}
