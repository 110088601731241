import { useCallback, useState } from "react";
import LRUCache from "lru-cache";
import { execute } from "./GetAllEmployees";
import EmployeesList from "../Presentation/EmployeesList";
import { EmployeeFormDialog } from "../Presentation/Form/EmployeeFormDialog";
import { RemoteEmployeesRepository } from "../Infrastructure/RemoteEmployeesRepository";
import { InMemoryEmployeesRepositoryDecorator } from "../Infrastructure/InMemoryEmployeesRepositoryDecorator";

const lru = new LRUCache({ maxAge: 1000 * 60 * 60 })

export const makeEmployeesList = ({ unauthErrorHandler, business, branch }) => () => {
    const remote = new RemoteEmployeesRepository(branch, business)
    const repository = new InMemoryEmployeesRepositoryDecorator(remote, lru)
    const getAllEmployees = execute(repository)
    
    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState(null)

    const [query, setQuery] = useState('')
    const [newEmployeeDialogOpen, setNewEmployeeDialogOpen] = useState(false)

    const loadEmployees = useCallback(() => {
        return getAllEmployees(query).then(setFilteredEmployees);
    }, [query, getAllEmployees])
    
    const load = useCallback(() => {
        loadEmployees()
            .catch(e => {
                if (e.status === 401) unauthErrorHandler()
            })
    }, [loadEmployees])

    return <>
        <EmployeesList
            onLoad={load}
            onQueryChange={query => {
                setQuery(query)
                loadEmployees()
            }}
            employees={filteredEmployees}
            onEmployeeSelected={setSelectedEmployee}
            onCreateEmployee={() => setNewEmployeeDialogOpen(true)}
        />
        <EmployeeFormDialog
            open={selectedEmployee != null}
            onSubmit={s => {
                repository.update(s)
                    .then(loadEmployees)
                    .then(() => setSelectedEmployee(null))
            }}
            onCancel={() => setSelectedEmployee(null)}
            employee={selectedEmployee}
        />

        <EmployeeFormDialog
            open={newEmployeeDialogOpen}
            onSubmit={s => {
                repository.create(s)
                    .then(loadEmployees)
                    .then(() => setNewEmployeeDialogOpen(false))
            }}
            onCancel={() => setNewEmployeeDialogOpen(false)}
        />
    </>
}
