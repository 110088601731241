export class InMemoryServiceCategoriesRepositoryDecorator {
    constructor(decoratee, cache) {
        this.decoratee = decoratee;
        this.cache = cache;
    }

    async getById(id) {
        const cached = this.read();
        if (cached && cached.find(c => c.id === id))
            return cached.find(c => c.id === id);
        const remote = await this.decoratee.getById(id);
        if (cached) {
            const toCache = cached
            toCache.push(remote)
            this.store(toCache);
        }
        return remote;
    }

    async getAll() {
        const cached = this.read();
        if (cached)
            return cached;
        const remote = await this.decoratee.getAll();
        this.store(remote);
        return remote;

    }

    async create(serviceCategory) {
        this.cache.reset()
        await this.decoratee.create(serviceCategory)
    }

    async update(serviceCategory) {
        this.cache.reset()
        await this.decoratee.update(serviceCategory)
    }

    store(serviceCategories) {
        this.cache.set('serviceCategories_list', serviceCategories);
    }

    read() {
        return this.cache.get('serviceCategories_list');
    }
}
