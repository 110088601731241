import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";

export function ActionFormPasswordField({ label, value, onChange, isRequired = true }) {
    const [passwordHidden, setPasswordHidden] = useState(true)

    return (
        <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
                type={passwordHidden ? 'password' : 'text'}
                value={value}
                onChange={onChange}
                required={isRequired}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordHidden(!passwordHidden)}
                        edge="end"
                    >
                        {passwordHidden ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>}
                label="Password" />
        </FormControl>
    );
}
