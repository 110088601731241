export class InMemoryBusinessesRepositoryDecorator {
    constructor(decoratee, cache) {
        this.decoratee = decoratee;
        this.cache = cache;
    }

    async getAll() {
        const cached = this.read();
        if (cached)
            return cached;
        const remote = await this.decoratee.getAll();
        this.store(remote);
        return remote;

    }

    async create(business) {
        this.cache.reset()
        await this.decoratee.create(business)
    }

    async update(business) {
        this.cache.reset()
        await this.decoratee.update(business)
    }

    store(businesses) {
        this.cache.set('businesses_list', businesses);
    }

    read() {
        return this.cache.get('businesses_list');
    }
}
