import { TextField } from "@mui/material";

export function ActionFormTextField({ style, label, value, onChange, variant = 'outlined', isRequired = false, isMultiline = false, type, placeholder, disabled = false }) {
    return (
        <TextField className='text-field'
            style={style}
            multiline={isMultiline}
            value={value}
            label={label}
            variant={variant}
            onChange={onChange}
            required={isRequired}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
        />
    );
}
