import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Menu } from '@mui/icons-material'
import './NavBar.scss'

export default function NavBar({ onMenuClick, title, subtitle, onTitleClick, children }) {
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    onClick={onMenuClick}
                    edge="start" className='menu-button' color="inherit" aria-label="menu">
                    <Menu />
                </IconButton>
                <Box
                    className='title'
                    sx={{
                        display: {
                            xs: 'none', sm: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                        }
                    }}
                >
                    <Typography
                        variant="h6"
                        onClick={onTitleClick}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="Subtitle1"
                        onClick={onTitleClick}
                    >
                        {subtitle}
                    </Typography>
                </Box>
                <Box
                    className='title'
                    sx={{
                        display: {
                            xs: 'flex', sm: 'none',
                            flexWrap: 'wrap',
                        }
                    }}>
                    <Typography
                        variant="h6"
                        onClick={onTitleClick}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="caption"
                        onClick={onTitleClick}
                    >
                        {subtitle}
                    </Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'flex', gap: '0.5rem' } }}>
                    {children}
                </Box>
            </Toolbar>
        </AppBar >
    );
}