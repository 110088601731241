import { Divider, ListItemButton, ListItemIcon, List, ListItem, ListItemText, Typography, Button, Box } from "@mui/material";
import InboxIcon from '@mui/icons-material/Person';
import ServicesIcon from '@mui/icons-material/ShoppingBag';
import DurationIcon from '@mui/icons-material/Timer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { prettyDuration, prettyLongDate, prettyTime } from "../../Shared/prettyLongDate";

export function FinalStep({ selectedClient, selectedDate, selectedServices, modifyClient, modifyDate, modifyServices, onConfirm }) {
    return <div style={{
        maxWidth: 500,
        margin: 'auto',
        padding: '1rem',
        gap: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <List style={{
            border: '1px solid #ccc',
            borderRadius: 8,
            width: '100%'
        }}>
            <ListItem disablePadding>
                <ListItemButton onClick={modifyClient}>
                    <ListItemIcon>
                        <InboxIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant='h6'>
                            {selectedClient?.name + ' ' + selectedClient?.last_name}
                        </Typography>}
                        secondary={selectedClient?.other || 'No hay comentarios'}
                    />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
                <ListItemButton onClick={modifyDate}>
                    <ListItemIcon>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography variant='h6'>
                            Fecha y hora
                        </Typography>
                    } secondary={<Box
                        component='span'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem'
                        }}>
                        <Typography component='span' variant="body">
                            {prettyLongDate(selectedDate)}
                        </Typography>
                        <Typography component='span' variant="body1">
                            {prettyTime(selectedDate)}
                        </Typography>
                    </Box>}
                    />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
                <ListItemButton onClick={modifyServices}>
                    <ListItemIcon>
                        <ServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography variant='h6'>
                            Servicios
                        </Typography>
                    } secondary={
                        selectedServices.map(service => <FinalStepServiceItem key={service.id} service={service} />)
                    }
                    />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <DurationIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Duracion total"}
                        secondary={prettyDuration(selectedServices.reduce((acc, service) => acc + parseInt(service.duration), 0))}
                    />
                </ListItemButton>
            </ListItem>
        </List>
        <Button
            style={{ width: '100%' }}
            variant='contained'
            onClick={onConfirm}
        >Confirmar</Button>
    </div>
}

function FinalStepServiceItem({ service }) {
    return (
        <Box
            component='span'
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem'
            }}>
            <Typography component='span' variant='body1'>{service.displayable_name}</Typography>
            <Typography component='span' variant='body2'>{service.duration} minutos</Typography>
            <Divider component='span' />
        </Box>
    );
}