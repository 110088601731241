import { baseURL } from "../../Shared/Networking/constants";
import { jsonRequest } from "../../Shared/Networking/rest-client";

export class RemoteServicesRepository {
    constructor(selectedBusiness) {
        this.selectedBusiness = selectedBusiness
    }

    async getAll() {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/services`
        return await jsonRequest(`${url}`).then(response => response.data)
    }

    async create(service) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/services`
        return await jsonRequest(`${url}`, { method: 'POST', body: this.bodyFromService(service) })
    }

    async update(service) {
        const url = `${baseURL}/businesses/${this.selectedBusiness}/services`
        return await jsonRequest(`${url}`, { method: 'PUT', body: this.bodyFromService(service) })
    }

    bodyFromService(service) {
        const body = {
            displayable_name: service.displayable_name,
            duration: service.duration,
            name: service.name,
            category_id: service.category_id
        }
        if (service.id) {
            body.id = service.id
        }
        return body
    }
}
