import { Button } from '@mui/material'
import React, { useState } from 'react'
import { ActionForm } from '../../../Shared/Components/ActionForm/ActionForm'
import { ActionFormContent } from '../../../Shared/Components/ActionForm/ActionFormContent'
import { ActionFormTextField } from '../../../Shared/Components/ActionForm/ActionFormTextField'
import { ActionFormPasswordField } from '../../../Shared/Components/ActionForm/ActionFormPasswordField'
import { ActionFormButtonsContainer } from '../../../Shared/Components/ActionForm/ActionFormButtonsContainer'

export default function SignupForm({ signupWithCredentials, error, goToLogin }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    function handleSubmit(event) {
        event.preventDefault();
        signupWithCredentials({ email, password })
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <ActionForm handleSubmit={handleSubmit} title='Sign up'>
                <ActionFormContent>
                    <ActionFormTextField
                        label={'Email'}
                        onChange={handleEmail}
                        value={email}
                        error={error}
                        helperText={error}
                        placeholder="your_name@business.com"
                        isRequired={false}
                    />
                    <ActionFormPasswordField
                        label={'Password'}
                        onChange={handlePassword}
                        value={password}
                    />
                </ActionFormContent>
                <ActionFormButtonsContainer>
                    <Button
                        onClick={goToLogin}
                        >
                        I have an account
                    </Button>
                    <Button type='submit' variant='contained' color="primary">
                        Sign Up
                    </Button>
                </ActionFormButtonsContainer>
            </ActionForm>
        </div>
    )
}
