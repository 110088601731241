import { useState } from "react";
import { DefaultEmptyView } from "../../Shared/Components/DefaultEmptyView";
import ListItem from "../../Shared/Components/Lists/ListItem";
import QueryableList from "../../Shared/Components/Lists/Queryable/QueryableList";

export function ClientSelectionStep({ createClientWithDocNumber, searchClients, filteredClients, onClientSelected }) {
    const [query, setQuery] = useState('');

    return <QueryableList
        style={{
            maxWidth: 750,
            width: '100%',
            margin: 'auto',
        }}
        searchFieldPlaceholder="Nombre o DNI"
        onQueryChange={q => {
            setQuery(q);
            searchClients(q);
        }}
        subheaders={['Nombre', 'DNI']}
        emptyListView={<DefaultEmptyView title={"Buscar cliente"} />}
        emptyQueriedListView={<div style={{
            maxWidth: 400,
            margin: 'auto'
        }}>
            <DefaultEmptyView
                title={`Ningun Resultado. Crear cliente con DNI: ${query}`}
                onActionClick={() => createClientWithDocNumber(query)}
            />
        </div>
        }
    >
        {filteredClients.map((client) => (
            <ListItem
                key={client.id}
                columns={[`${client.last_name}, ${client.name}`, client.identification?.number]}
                onItemSelected={() => onClientSelected(client)} />
        ))}
    </QueryableList>;
}
