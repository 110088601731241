import { useCallback, useState } from "react"
import { useLocation } from "wouter"
import { RemoteBranchesRepository } from "../../Branches/Infrastructure/RemoteBranchesRepository"
import { RemoteBusinessesRepository } from "../../Businesses/Infrastructure/RemoteBusinessesRepository"
import BranchSelection from "../BranchSelection"
import { selectBranch } from './BranchAndBusinessSelection'

const businessRepository = new RemoteBusinessesRepository()
const branchesRepository = new RemoteBranchesRepository()
export const makeBranchSelection = () => () => {
    const [businesses, setBusinesses] = useState([])
    const setLocation = useLocation()[1]
    const loadBranches = useCallback(async () => {
        try {
            const bss = await businessRepository.getAll()
            const mapped = []
            for (const b of bss) {
                try {
                    var branches = await branchesRepository.getAll(b.id)
                    branches = branches.map(br => { return { ...br, businessId: b.id } })
                    mapped.push({ ...b, branches })
                } catch (e) { }
            }
            setBusinesses(mapped)
        } catch (e) { }
    }, [])
    const load = useCallback(loadBranches, [loadBranches])
    return <BranchSelection onLoad={load} businesses={businesses} onBranchSelected={(bu, br) => {
        selectBranch(bu, br)
        setLocation('/')
    }} />
}
